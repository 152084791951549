import React, { useState, SyntheticEvent, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  DraggableProvidedDragHandleProps,
  DraggableProvidedDraggableProps,
} from 'react-beautiful-dnd';

import { socketHandlers } from '../../common/signalR/signals';
import { StoreState } from '../../app/app.reducers';
import { Card, ColumnColors } from '../../models/api/api.models';
import { translate, translationKeys } from '../../logic/translations/translations.service';

import { ReactComponent as VoteIcon } from '../../assets/icons/vote.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as MergeIcon } from '../../assets/icons/merge.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-board.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { BoardCardContent } from './BoardCardContent';

import './BoardCard.scss';

const ANONYMOUS_NAME = translate(translationKeys.anonymousUserName);

const initEditMode = (cardId: string, subCards: Card[]) =>
  subCards.reduce(
    (acc: Record<string, boolean>, curr: GeneratedApiModels.Card) =>
      curr.id ? { ...acc, [curr.id]: false } : acc,
    {
      [cardId]: false,
    }
  );

interface Props {
  innerRef?: any;
  draggableProps: DraggableProvidedDraggableProps;
  handleProps?: DraggableProvidedDragHandleProps;
  cardId: string;
  columnId: string;
  isDisabled: boolean;
  displayId: string;
  text: string;
  subCards: Card[];
  author: string;
  votes: string[];
  votesVisibility: boolean | undefined;
  authorsVisibility: boolean | undefined;
  isBeingEdited: boolean;
  userId: string | undefined;
  color: ColumnColors;
  isDraggedOver: boolean;
  onEdit: (
    boardId: string,
    columnId: string,
    cardId: string,
    text: string,
    subcardId?: string
  ) => void;
  onRemove: (columnId: string, cardId: string) => void;
  addVote: (boardId: string | undefined, columnId: string, cardId: string) => void;
  removeVote: (boardId: string | undefined, columnId: string, cardId: string) => void;
  onMerge: (boardId: string, columnId: string, cardId: string) => void;
  onUnMerge: (boardId: string, columnId: string, cardFromId: string, cardId: string) => void;
  onCardClick: (boardId: string | undefined, columnId: string, cardId: string) => void;
  onStopEdit: (boardId: string | undefined, columnId: string, cardId: string) => void;
}

export const BoardCard: React.FC<Props> = ({
  cardId,
  columnId,
  text,
  subCards = [],
  author,
  votes,
  isBeingEdited = false,
  votesVisibility = true,
  authorsVisibility = true,
  userId,
  displayId,
  innerRef,
  isDisabled,
  isDraggedOver,
  draggableProps,
  handleProps,
  color,
  onEdit,
  onRemove,
  addVote,
  removeVote,
  onMerge,
  onUnMerge,
  onCardClick,
  onStopEdit,
}) => {
  const board = useSelector((state: StoreState) => state.board.board);
  const boardId = board?.id;
  const isMerged = subCards.length > 0;
  const subCardsVotes = subCards
    .map(({ votes }) => (votes ? votes.length : 0))
    .reduce((acc, votes) => (acc += votes), 0);
  const userVotes = votes.filter((vote) => vote === userId);
  const [editMode, setEditMode] = useState(initEditMode(cardId, subCards));
  const isInEditMode = Object.values(editMode).some(Boolean);
  const myCard = useSelector((state: StoreState) => state.board.myCard);

  const { vote, removeUserVote, remove } = translationKeys.pages.boardPage.cardSettings;

  const rootClasses = clsx(
    'board-card',
    isBeingEdited && !isInEditMode && 'board-card--is-being-edited',
    isMerged && 'board-card--merged',
    isDraggedOver && `board-card--dragged-over`,
    `board-card--${color ?? 'default'}`
  );

  const handleCardClick = useCallback((columnId: string, cardId: string, boardId?: string) => {
    if (boardId) onCardClick(boardId, columnId, cardId);
    !isDisabled && setEditMode({ ...editMode, [cardId]: !editMode[cardId] });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (myCard !== {} && myCard?.columnId && myCard?.cardId) {
      if (cardId === myCard.cardId) {
        handleCardClick(myCard.columnId, myCard.cardId, myCard.boardId);
      }
    }
  }, [cardId, handleCardClick, myCard]);

  const handleContentChangeSubmit = (cardId: string, text: string, subcardId?: string) => {
    if (boardId) {
      if (text.trim() === '') {
        socketHandlers.removeCard(boardId, columnId, cardId);
      } else {
        onEdit(boardId, columnId, cardId, text, subcardId);
      }
    }
  };

  const handleRemoveCard = () => {
    boardId && onRemove(columnId, cardId);
  };

  const handleUnMergeCard = (cardFromId: string, cardId: string) => {
    boardId && onUnMerge(boardId, columnId, cardFromId, cardId);
  };

  const handleEditMode = (id: string, isEdited: boolean) => {
    setEditMode({ ...editMode, [id]: isEdited });
    onCardClick(boardId, columnId, cardId);
    boardId && onStopEdit(boardId, columnId, cardId);
  };

  const handleOnBlur = () => {
    boardId && onStopEdit(boardId, columnId, cardId);
  };

  const handleAddVote = (e: SyntheticEvent) => {
    e.stopPropagation();
    !isDisabled && addVote(boardId, columnId, cardId);
  };

  const handleRemoveVote = (e: SyntheticEvent) => {
    e.stopPropagation();
    !isDisabled && removeVote(boardId, columnId, cardId);
  };

  const authorName = (authorId: string | undefined) => {
    if (authorsVisibility) {
      return board?.nameRegistry && authorId
        ? board.nameRegistry[authorId] ?? ANONYMOUS_NAME
        : ANONYMOUS_NAME;
    }
    return '';
  };

  return (
    <div
      className={rootClasses}
      ref={innerRef}
      {...draggableProps}
      {...handleProps}
      onClick={() => handleCardClick(columnId, cardId, boardId)}
      onBlur={handleOnBlur}
    >
      <div className="board-card__main-wrapper">
        <div className="board-card__controls">
          <div className="board-card__id">{'#' + displayId}</div>
          <div className="board-card__side">
            <div className="board-card__votes">
              {userVotes &&
                userVotes.map((e, i) => <span key={i} className="board-card__votes-circle" />)}
              {userVotes && userVotes.length > 0 && (
                <button
                  className="board-card__btn board-card__btn--close"
                  onClick={handleRemoveVote}
                  title={translate(removeUserVote)}
                >
                  <CloseIcon width="8px" height="8px" />
                </button>
              )}
              <button
                className="board-card__btn board-card__btn--vote"
                title={translate(vote)}
                onClick={handleAddVote}
              >
                <VoteIcon />
              </button>
              {votesVisibility && (
                <span className="board-card__votes-count">{votes.length + subCardsVotes}</span>
              )}
            </div>
          </div>
        </div>
        <div className="board-card__content">
          {isBeingEdited && !isInEditMode ? (
            <div className="board-card__content-editing">
              <div className="dots">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <EditIcon />
            </div>
          ) : isMerged ? (
            subCards.map(({ text, author, id }, idx: number) => (
              <BoardCardContent
                key={idx}
                cardId={cardId}
                subCardId={id}
                text={text}
                setEditMode={handleEditMode}
                isInEditMode={editMode[id || '']}
                author={authorName(author)}
                onSubmit={handleContentChangeSubmit}
                onUnMerge={handleUnMergeCard}
                single={false}
              />
            ))
          ) : (
            <BoardCardContent
              cardId={cardId}
              text={text}
              setEditMode={handleEditMode}
              isInEditMode={editMode[cardId]}
              author={authorName(author)}
              onSubmit={handleContentChangeSubmit}
              single
            />
          )}
        </div>
      </div>
      <div className="board-card__footer">
        <div className="board-card__footer-content board-card__footer-content--left">
          {!isMerged && (
            <div className="board-card__owner">
              <UserIcon />
              <span className="board-card__owner-name">{authorName(author)}</span>
            </div>
          )}
        </div>
        {!isDisabled && (
          <div className="board-card__footer-content board-card__footer-content--right">
            <div className="board-card-withsvg">
              Merge
              <MergeIcon />
            </div>
            <button
              onClick={handleRemoveCard}
              className="board-card__btn board-card-withsvg board-card__btn--delete"
              title={translate(remove)}
            >
              Delete
              <TrashIcon />
            </button>
          </div>
        )}
      </div>
      <div
        className={clsx(
          'board-card__merge-indicator',
          isDraggedOver && 'board-card__merge-indicator--active'
        )}
      >
        <span>Merge with this card</span>
        <MergeIcon width="50" height="50" />
      </div>
    </div>
  );
};
