import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMedia } from 'react-use';
import { translate, translationKeys } from '../../logic/translations/translations.service';
import clsx from 'clsx';
import { Droppable } from 'react-beautiful-dnd';

import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { ColorPicker } from '../ColorPicker';
import { StoreState } from '../../app/app.reducers';
import { getOrderedColumns } from '../../app/app.selectors.helper';

import './BoardColumn.scss';
import { ColumnColors } from '../../models/api/api.models';
import { socketHandlers } from '../../common/signalR/signals';
import useDetectClickOutside from '../../logic/hooks/useDetectClickOutside';
import { BoardColumnAddCard } from "./BoardColumnAddCard";

interface Props {
  id: string;
  name: string;
  order?: number;
  color: ColumnColors;
  isDisabled?: boolean;
  onAddCard: (boardId: string, columnId: string, text?: string) => void;
  onRemove: (columnId: string) => void;
  onRename: (newColumnName: string) => (e: React.FocusEvent) => void;
  onChangeOrder: (boardId: string, columnId: string, order: number) => void;
}

enum OrderDirection {
  Left = 'left',
  Right = 'right',
}

export const BoardColumn: React.FC<Props> = ({
  id,
  name,
  order,
  color,
  children,
  onAddCard,
  onRemove,
  onRename,
  onChangeOrder,
  isDisabled,
}) => {
  const boardId = useSelector((state: StoreState) => state.board.board?.id);
  const orderedColumns = useSelector(getOrderedColumns);
  const containerRef = useDetectClickOutside(
    () => toggleEditMode(),
    () => isEditMode
  );
  const [isEditMode, setEditMode] = useState(false);
  const [newColumnName, setNewColumnName] = useState(name);
  const isBelow600Width = useMedia('(max-width: 599px)');

  const {
    remove,
    moveToLeft,
    moveToRight,
    openSettings,
    closeSettings,
  } = translationKeys.pages.boardPage.columnSettings;

  useEffect(() => {
    setNewColumnName(name);
  }, [name]);

  const toggleEditMode = () => !isDisabled && setEditMode(!isEditMode);
  const changeOrder = (direction: OrderDirection) => {
    if (
      typeof order === 'undefined' ||
      !boardId ||
      !orderedColumns ||
      (direction === OrderDirection.Left && order <= 0) ||
      (direction === OrderDirection.Right && order === orderedColumns.slice(-1)[0].order)
    ) {
      return;
    }

    direction === OrderDirection.Left
      ? onChangeOrder(boardId, id, order - 1)
      : onChangeOrder(boardId, id, order + 1);
  };
  const moveLeft = () => changeOrder(OrderDirection.Left);
  const moveRight = () => changeOrder(OrderDirection.Right);

  const onNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setNewColumnName(e.currentTarget.value);
  const onNameInputKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  const handleColorChange = (color: ColumnColors) =>
    boardId && socketHandlers.changeColumnColor(boardId, id, color);

  const renderEditIcons = (isEditMode: boolean) => {
    if (!isEditMode) {
      return null;
    }

    return (
      <>
        <button
          title={translate(remove)}
          onClick={() => onRemove(id)}
          className="board-column__icon-btn"
        >
          <TrashIcon />
        </button>
        <button title={translate(moveToLeft)} onClick={moveLeft} className="board-column__icon-btn">
          <LeftIcon />
        </button>
        <button
          title={translate(moveToRight)}
          onClick={moveRight}
          className="board-column__icon-btn"
        >
          <RightIcon />
        </button>
      </>
    );
  };

  return (
    <div className={clsx('board-column', isEditMode && 'board-column--active')} ref={containerRef}>
      <div className="board-column__header">
        <div className="board-column__topbar">
          <div className="board-column__topbar-content board-column__topbar-content--left">
            <div className="board-column__topbar-content-title-wrapper">
              <ColorPicker pickedColor={color} changeColor={handleColorChange} />
              {isEditMode ? (
                <input
                  className="board-column__title-input"
                  value={newColumnName}
                  onChange={onNameInputChange}
                  onKeyDown={onNameInputKeyDown}
                  type="text"
                  autoFocus={true}
                  onBlur={onRename(newColumnName)}
                />
              ) : (
                <p className="board-column__title">{newColumnName}</p>
              )}
            </div>
            {isBelow600Width && (
              <button
                onClick={toggleEditMode}
                className="board-column__icon-btn board-column__icon-btn--openclose"
                title={isEditMode ? translate(closeSettings) : translate(openSettings)}
              >
                {isEditMode ? <CloseIcon /> : <SettingsIcon />}
              </button>
            )}
          </div>
          <div className="board-column__topbar-content board-column__topbar-content--right">
            {renderEditIcons(isEditMode)}
            {!isBelow600Width && (
              <button
                onClick={toggleEditMode}
                className="board-column__icon-btn board-column__icon-btn--openclose"
                title={isEditMode ? translate(closeSettings) : translate(openSettings)}
              >
                {isEditMode ? <CloseIcon /> : <SettingsIcon />}
              </button>
            )}
          </div>
        </div>
        {!isDisabled && (
          <BoardColumnAddCard
            columnId={id}
            onAddCard={onAddCard}
          />
        )}
      </div>
      <Droppable key={id} droppableId={id} isCombineEnabled>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps} className="board-column__body">
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};
