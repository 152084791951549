import React, { createRef, useEffect, useState } from 'react';
import { KeyCodes } from '../../common/consts';
import { getSecondsFromHHMMSS } from './Timer.logic';

type Props = {
  displayValue: string;
  toggleActiveInput: () => void;
  onBlur?: (seconds: number) => void;
};

const TimerInput = ({ displayValue = '', onBlur, toggleActiveInput }: Props) => {
  const [displayName, setDisplayName] = useState(displayValue);
  const inputRef = createRef<HTMLInputElement>();

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    const seconds = getSecondsFromHHMMSS(value);
    onBlur && onBlur(seconds);
    toggleActiveInput();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode !== KeyCodes.ENTER_KEY_CODE && e.keyCode !== KeyCodes.ESCAPE_KEY_CODE) return;

    if (e.keyCode === KeyCodes.ESCAPE_KEY_CODE) {
      toggleActiveInput();
      return;
    }

    inputRef.current?.blur();
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  return (
    <input
      className="timer__input"
      type="text"
      ref={inputRef}
      value={displayName}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onChange={(e) => setDisplayName(e.target.value)}
    />
  );
};

export default TimerInput;
