import React from 'react';

export interface Props {
  innerRef?: any;
};

export const BoardBody: React.FC<Props> = ({ children, innerRef }) => {
  return (
    <div className="board__body" ref={innerRef}>
      {children}
    </div>
  );
};
