import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from '../../assets/icons/left.svg';
import './PolicyPage.scss';

interface Props {

};

export const PolicyPage: React.FC <Props> = () => {
  const browserHistory = useHistory();

  return (
    <div className="policy-page">
      <div className="policy-content">
        <div className="policy-content__button-back">
          <ArrowLeft height="25px" width="25px" onClick={() => browserHistory.goBack()} />
        </div>
        <h1 className="policy-content__header">
          Privacy Policy of boldare.com
        </h1>
        <ol className="policy-list">
          <li>
            <h2 className="policy-list__header">Introduction</h2>
            <ol className="policy-sublist">
              <li>
                We are committed to safeguarding the privacy of our website visitors.
                By giving you this policy we’re explain how we maintain and treat your personal information.
              </li>
              <li>
                By using our website and agreeing to this policy, you consent to our use of cookies in accordance with the terms of this policy.
              </li>
            </ol>
          </li>
          <li>
            <h2 className="policy-list__header">What information do we collect about you?</h2>
            <ol className="policy-sublist">
              <li>
                We’re collecting or we may collect, store and use following kinds of personal information:
                <ol className="policy-sublist">
                  <li>
                    device and browser information including: IP address, cookie string data,  operating system and
                    (in the case of mobile devices) your device type and mobile device's unique identifier such as
                    the Apple IDFA or Android ID, ID Token Google;
                  </li>
                  <li>
                    information that you provide to us for the purpose of subscribing to our email notifications
                    and/or newsletters (including your name and email address);
                  </li>
                  <li>
                    information contained in or relating to any communication that you send to us or send through our
                    website (including metadata associated with the communication);
                  </li>
                  <li>
                    information necessary to recruitment process;
                  </li>
                  <li>
                    any other personal information that you decided to send to us.
                  </li>
                </ol>
              </li>
              <li>
                By sending personal information of another person, you must obtain that person's consent to both
                the disclosure and the processing of that personal information in accordance with this policy.
              </li>
            </ol>
          </li>
          <li>
            <h2 className="policy-list__header">Why we need your personal Information?</h2>
            <ol className="policy-sublist">
              <li>
                We collect information about you to; fulfill your request e.g. if you fill out a ‘request a callback’
                or ‘download a ebook’ form we use this information to contact you directly, process your business inquiry,
                process your job application, request for project, to email you about new content on website,
                to email you about new blog article, to email you about new ebook, to email you about events which we organise.
              </li>
              <li>
                We may use your contact information to advertise our service to you on third-party websites such as Google,
                LinkedIn, Facebook and Twitter. We may also contact you directly to follow up on the service and user
                experience we provide. We use your information collected from the website to personalize your repeat visits to our website.
              </li>
              <li>
                By providing your personal data you agree to the sharing of your data with third parties (partners and sponsors)
                so that they may contact you. If BOLDARE S.A. is ever sold to another company your contact information will be passed on to this new party.
              </li>
              <li>
                We will take cautionary measure to ensure we do not collect any personal data from you we do not need
                in order to provide and oversee the above services to you.
              </li>
              <li>
                We may also use your personal information to:
                <ol className="policy-sublist">
                  <li>
                    personalise our website for you;
                  </li>
                  <li>
                    send you invoices and payment reminders to collect payments from you;
                  </li>
                  <li>
                    send you other non-marketing communications;
                  </li>
                  <li>
                    keep our website secure and prevent fraud.
                  </li>
                </ol>
              </li>
              <li>
                If you have consented to receive marketing, you may opt out at a later date. You have a right at any
                time to stop us from contacting you for marketing purposes or giving your information to other parties.
                If you no longer wish to be contacted for marketing purposes please email us at marketing@boldare.com or
                unsubscribe from our next email.
              </li>
            </ol>
          </li>
          <li>
            <h2 className="policy-list__header">Data sharing and disclosing personal information</h2>
            <ol className="policy-sublist">
              <li>
                We may disclose information about you:
                <ol className="policy-sublist">
                  <li>
                    With our service providers: We contract with companies who help with parts of our business
                    operations (e.g., for example, website and data hosting, fraud prevention, viewability reporting,
                    data hygiene, marketing, and email delivery), as well as billing, collections, tech, customer and operational support;
                  </li>
                  <li>
                    With our subsidiaries and related companies: But they will only process your data for the purposes
                    already explained in this Privacy Notice. Our subsidiaries and related companies are:
                    <label className="policy-list__bold"> BOLDARE S.A.</label>, registration number 0000914518,
                    <label className="policy-list__bold"> Solution Expert Sp. z o.o.</label> registration number 0000618844.
                  </li>
                  <li>
                    In connection with legal proceedings: When we are under a legal obligation to do so, for example
                    to comply with a binding order of a court, or where disclosure is necessary to exercise, establish
                    or defend the legal rights of BOLDARE S.A. or any other third party.
                  </li>
                  <li>
                    To Comply with legal process: To satisfy in good faith any applicable law, legal process, or proper
                    governmental request, such as to respond to a subpoena (whether civil or criminal) or similar process.
                  </li>
                  <li>
                    To Investigate Wrongdoing and Protect Ourselves or Third Parties: To enforce our Terms of Service
                    or other policies or investigate any potential violation of those Terms and policies, any potential
                    violation of the law, or to protect ourselves, our customers, or any third party from any potential
                    harm (whether tangible or intangible).
                  </li>
                  <li>
                    In connection with a sale of our business: If a third party acquires some or all of our business
                    or assets, we may disclose your information in connection with the sale (including during due
                    diligence in preparation for the sale).
                  </li>
                  <li>
                    with any of our employees, officers, insurers, professional advisers, agents, suppliers or
                    subcontractors insofar as reasonably necessary for the purposes set out in this policy.
                  </li>
                </ol>
              </li>
              <li>
                We also share hashed email addresses (or other pseudonymous identifiers associated with those hashes),
                technical data that we collect about your browsing habits and your device (such as data relating to our
                cookies, tracking pixels and similar technologies) with other advertising companies in the digital
                advertising ecosystem. This enables them and us to better target ads to yo
              </li>
            </ol>
          </li>
          <li>
            <h2 className="policy-list__header">How long will we store your data?</h2>
            <ol className="policy-sublist">
              <li>
                If BOLDARE S.A. provides any paid for services to you, we are required under Polish tax law to keep your basic
                personal data (name, address, contact details) for a minimum of 5 years after which time it will be destroyed.
                Your information we use for marketing purposes will be kept with us until you notify us that you no
                longer wish to receive this information.
              </li>
              <li>
                We will not, without your consent including section IV point 1 supply your personal information to any third party.
              </li>
            </ol>
          </li>
          <li>
            <h2 className="policy-list__header">What are your rights?</h2>
            <ol className="policy-sublist">
              <li>
                You have the:
                <ol className="policy-sublist">
                  <li>
                    the right to access;
                  </li>
                  <li>
                    the right to rectification;
                  </li>
                  <li>
                    the right to erasure;
                  </li>
                  <li>
                    the right to restrict processing;
                  </li>
                  <li>
                    the right to object to processing;
                  </li>
                  <li>
                    the right to data portability;
                  </li>
                  <li>
                    the right to complain to a supervisory authority; and
                  </li>
                  <li>
                    the right to withdraw consent.
                  </li>
                </ol>
              </li>
              <li>
                If you would like use one of the rights from point, please email or write to us at the following a
                ddress: ado@boldare.com Address: Królowej Bony 13, 44-100 Gliwice, Poland with request of using particular right.
              </li>
              <li>
                We want to make sure that your personal information is accurate and up to date. You may ask us to
                correct or remove information you think is inaccurate.
              </li>
              <li>
                If you are not satisfied with our response or believe we are processing your personal data not in
                accordance with the law you can complain to the GIODO Data Protection https://www.giodo.gov.pl/
              </li>
              <li>
                We may withhold personal information that you request to the extent permitted by law.
              </li>
            </ol>
          </li>
          <li>
            <h2 className="policy-list__header">Cookies, HotJar and Mouseflow</h2>
            <ol className="policy-sublist">
              <li>
                Cookies are text files placed on your computer to collect standard internet log information and visitor
                behavior information. This information is used to track visitor use of the website and to compile
                statistical reports on website activity. For further information visit www.aboutcookies.org or www.allaboutcookies.org.
              </li>
              <li>
                You can set your browser not to accept cookies and the above websites tell you how to remove cookies
                from your browser. However, in a few cases, some of our website features may not function as a result.
              </li>
              <li>
                We’re using also Mouseflow https://mouseflow.com/ and Hotjar https://www.hotjar.com/  to track your
                activity at our website (for example: which pages you visited and when, what items were clicked on a page,
                how much time was spent on a page, whether you downloaded).
              </li>
            </ol>
          </li>
          <li>
            <h2 className="policy-list__header">Google Analytics</h2>
            Google Analytics is a web analysis service provided by Google Inc. (“Google”). Google utilizes the Data
            collected to track and examine the use of this Application, to prepare reports on its activities and share
            them with other Google services. Google may use the Data collected to contextualize and personalize the ads
            of its own advertising network. Personal Data collected: Cookie and Usage data
          </li>
          <li>
            <h2 className="policy-list__header">Changes to this privacy policy</h2>
            We keep our privacy policy under regular review and we will place any updates on this web page.
            This privacy policy was last updated on 21st May 2018.
          </li>
          <li>
            <h2 className="policy-list__header">Other websites</h2>
            Our website contains links to other websites. This privacy policy only applies to this website so when you
            link to other websites you should read their own privacy policies.
          </li>
          <li>
            <h2 className="policy-list__header">Security</h2>
            We apply technical, administrative and organizational security measures to protect the data we collect
            against accidental or unlawful destruction and loss, alteration, unauthorized disclosure or access,
            in particular where the processing involves the transmission of data over a network, and against other
            unlawful forms of processing.
          </li>
          <li>
            <h2 className="policy-list__header">How to contact us and our details </h2>
            <ol className="policy-sublist">
              <li>
                Please contact us if you have any questions about our privacy policy or information we hold about
                you: email: ado@boldare.com  Address: Królowej Bony 13, 44-100 Gliwice, Poland
              </li>
              <li>
                This website is owned by BOLDARE S.A.
              </li>
              <li>
                We are registered in Poland in the register of entrepreneurs of the Regional Court in Gliwice Economic
                department X of the National Court Register under KRS number 0000914518, NIP: 6312698829,
                the REGON: 389585559, and our registered office is at Królowej Bony 13, 44-100 Gliwice, Poland.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
};
