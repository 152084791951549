export const en = {
  pages: {
    createBoardPage: {
      createNewRetroBoard: 'Create new board',
      fields: {
        boardName: 'Name your board',
        maxVotes: 'Set max votes number',
      },
      buttons: {
        createBoard: 'Create board',
      },
      validations: {
        missingFields: 'You have to enter all fields!',
        notValidNumber: 'You have to enter valid number',
      },
    },
    boardPage: {
      columnName: 'Column',
      newColumnName: 'New column',
      boardName: 'Board name',
      boardSaveSuccess: 'Board saved successfuly.',
      boardSaveFailed: 'Failed to save the board.',
      boardExportFailed: 'Board export failed.',
      backButton: 'Back to previous view',
      settings: {
        settingsButton: 'Settings',
        setName: 'Set your name',
        setVotes: 'Set votes per user',
        saveBoard: 'Save current board',
        saveBoardTooltip: 'You need to login to save your board',
        exportBoard: 'Export current board',
        exportBoardTooltip: 'You need to login to save your board',
        toArchive: 'Go to saved boards',
        showTimer: 'Show timer',
      },
      columnSettings: {
        openSettings: 'Column settings',
        closeSettings: 'Close column settings',
        addCard: 'Add new card',
        changeCardColor: 'Change card color',
        moveToRight: 'Move Right',
        moveToLeft: 'Move Left',
        remove: 'Remove column',
      },
      cardSettings: {
        vote: 'Vote',
        removeUserVote: 'Remove vote',
        remove: 'Remove card',
        merge: 'Merge cards',
        split: 'Split merged cards',
      },
      topBar: {
        sortBytVotes: 'Sort by votes',
        sortBytOrder: 'Sort by order',
        hideVotes: 'Hide votes',
        showVotes: 'Show votes',
        hideUsers: 'Hide users',
        showUsers: 'Show users',
        addColumn: 'Add column',
        hideOptions: 'Hide options ',
        showOptions: 'Show options ',
      },
      modalTexts: {
        columnRemoveConfirmation: 'Are you sure you want to remove this column?',
        cardRemoveConfirmation: 'Are you sure you want to remove this card?',
        changeVotesPerUser: 'Set max votes per user:',
        changeUserName: 'Set your name:',
        saveButton: 'Save changes',
        cancelButton: 'Cancel',
        mergeConfirmation: 'Do you want to merge card #{{card1}} with card #{{card2}}?',
      },
      timer: {
        clock: 'Timer',
        startButton: 'Start',
        pauseButton: 'Pause',
        stopButton: 'Stop',
        hideButton: 'Hide timer',
      },
    },
    archivePage: {
      directoryCreationSuccess: 'Directory created successfully.',
      directoryCreationFail: 'Directory creation failed.',
      boardRemoveSuccess: 'Board removed successfully.',
      boardRemoveFail: 'Board removal failed.',
      boardsMovedSuccess: 'Boards moved successfully.',
      boardsMovedFail: 'Boards move failed.',
      directoryNameChangeSuccess: 'Directory name changed successfully.',
      directoryNameChangeFail: 'Directory name change failed.',
    },
  },
  buttons: {
    login: 'Login',
    logout: 'Logout',
  },
  messages: {
    loggedInSuccessfully: 'Logged in successfully',
    loggedOut: 'Logged out',
    votesNumberNotValid: 'Vote number is invalid',
    valueMissing: 'You have to provide a proper value',
    valueBetween: 'Value must be between',
    provideUserName: 'Provide user name',
    userNameTooLong: 'Provided user name is too long',
    columnAdded: 'New column has been added',
  },
  errors: {
    login: {
      errorDuringLogin: 'Error during login',
      errorDuringLogout: 'Error during logout',
    },
    merge: {
      errorMergeDifferentColumn: 'Can not merge cards from different columns',
    },
  },
  info: {
    loginModal: {
      heading: 'Log in and save your boards',
      description:
        'You can log in with your Google Account to save your boards and access them later!',
    },
    topBanner: {
      title: 'Alert:',
      text: 'Boldare Boards will be unavailable on [INSERT DATE&TIME] UTC due to planned server maintenance.',
      button: 'Ok, got it',
    }
  },
  anonymousUserName: 'Anonymous',
};
