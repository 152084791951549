import { connection } from './signalR';
import { store } from '../../app/app.store';
import { getUser } from '../../logic/store/auth/auth.selectors';
import { ColumnColors } from '../../models/api/api.models';

const defaultColumns = ['Went well', 'To improve', 'Actions'];

const createBoard = ({
  name,
  columns = defaultColumns,
  maxVotes,
  autoJoin = false,
}: {
  name: string;
  columns?: string[];
  maxVotes: number;
  autoJoin?: boolean;
}) => connection.invoke('CreateBoard', name, columns, maxVotes, autoJoin);

const joinBoard = (boardId: string) => {
  const user = getUser(store.getState());

  return user?.id
    ? connection.invoke('Rejoin', boardId, user.id)
    : connection.invoke('Join', boardId);
};

const addCardTop = (boardId: string, columnId: string, text?: string) =>
  connection.invoke('AddCard', boardId, columnId, text, 0);
const addCardBottom = (boardId: string, columnId: string, text?: string) =>
  connection.invoke('AddCard', boardId, columnId, text, -1);

const editCard = (
  boardId: string,
  columnId: string,
  cardId: string,
  text: string,
  subcardId?: string
) => connection.invoke('EditCard', boardId, columnId, cardId, subcardId, text);

const removeCard = (boardId: string, columnId: string, cardId: string) =>
  connection.invoke('RemoveCard', boardId, columnId, cardId);

const mergeCards = (boardId: string, columnId: string, cardId: string, destinationId: string) =>
  connection.invoke('MergeCards', boardId, columnId, cardId, destinationId);

const unMergeCard = (boardId: string, columnId: string, cardFromId: string, cardId: string) => {
  connection.invoke('UnMergeCard', boardId, columnId, cardFromId, cardId);
};

const indicateEditing = (boardId: string, columnId: string, cardId: string) =>
  connection.invoke('IndicateEditing', boardId, columnId, cardId);

const stopEditing = (boardId: string, columnId: string, cardId: string) =>
  connection.invoke('StopEditing', boardId, columnId, cardId);

const removeColumn = (boardId: string, columnId: string) =>
  connection.invoke('RemoveColumn', boardId, columnId);

const addColumn = (boardId: string, columnId: string) =>
  connection.invoke('AddColumn', boardId, columnId);

const setVotesVisibility = (boardId: string, votesVisible: boolean) => {
  connection.invoke('SetVoteVisibility', boardId, votesVisible);
};

const setCardsAuthorsVisibility = (boardId: string, cardsAuthorsVisible: boolean) => {
  connection.invoke('SetCardsAuthorsVisibility', boardId, cardsAuthorsVisible);
};

const setAvailableVotes = (boardId: string, newVotesPerUser: number) => {
  connection.invoke('SetAvailableVotes', boardId, newVotesPerUser);
};

const addVote = (boardId: string, columnId: string, cardId: string) => {
  connection.invoke('AddVote', boardId, columnId, cardId);
};

const removeVote = (boardId: string, columnId: string, cardId: string) => {
  connection.invoke('RemoveVote', boardId, columnId, cardId);
};

const setCardOrder = (boardId: string, columnId: string, cardId: string, order: number) => {
  connection.invoke('SetCardOrder', boardId, columnId, cardId, order);
};

const changeCardColumn = (
  boardId: string,
  columnId: string,
  cardId: string,
  targetColumnId: string,
  order: number
) => {
  connection.invoke('ChangeCardColumn', boardId, columnId, cardId, targetColumnId, order);
};

const renameColumn = (boardId: string, columnId: string, newName: string) =>
  connection.invoke('RenameColumn', boardId, columnId, newName);

const changeColumnOrder = (boardId: string, columnId: string, order: number) => {
  return connection.invoke('SetColumnOrder', boardId, columnId, order);
};

const startTimer = (boardId: string, seconds: number) =>
  connection.invoke('StartTimer', boardId, seconds);

const stopTimer = (boardId: string) => connection.invoke('StopTimer', boardId);

const pauseTimer = (boardId: string) => connection.invoke('PauseTimer', boardId);

const resumeTimer = (boardId: string) => connection.invoke('ResumeTimer', boardId);

const changeTimer = (boardId: string, seconds: number) =>
  connection.invoke('ChangeTimer', boardId, seconds);

const changeUsername = (boardId: string, userName: string) =>
  connection.invoke('ChangeUsername', boardId, userName);

const changeColumnColor = (boardId: string, columnId: string, newColor: ColumnColors) =>
  connection.invoke('ChangeColumnColor', boardId, columnId, newColor);

export const socketHandlers = {
  createBoard,
  joinBoard,
  addCardBottom,
  addCardTop,
  editCard,
  removeCard,
  mergeCards,
  unMergeCard,
  removeColumn,
  addColumn,
  indicateEditing,
  stopEditing,
  renameColumn,
  setVotesVisibility,
  setCardsAuthorsVisibility,
  setAvailableVotes,
  addVote,
  removeVote,
  setCardOrder,
  changeCardColumn,
  changeColumnOrder,
  startTimer,
  stopTimer,
  pauseTimer,
  resumeTimer,
  changeTimer,
  changeUsername,
  changeColumnColor,
};
