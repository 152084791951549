import React from 'react';
import './ProgressBar.scss';

interface Props {
  current: number,
  max: number,
  label: string;
};

export const ProgressBar: React.FC<Props> = ({ current, max, label }) => (
  <div className="progress-bar">
    <progress
      value={current}
      max={max}
    />
    <span>
      {label}
    </span>
  </div>
);

