import { Observable } from 'rxjs';

export function fromSignalObservable<T>(
  connection: signalR.HubConnection,
  signal: string
): Observable<T> {
  return new Observable((subscriber) => {
    connection.on(signal, (data) => subscriber.next(data));
    connection.onclose(() => subscriber.complete());

    return () => {
      connection.off(signal);
      subscriber.unsubscribe();
    };
  });
}
