import { GoogleLoginResponse, useGoogleLogin } from 'react-google-login';
import { env } from '../env/env';
import { authSlice } from '../store/auth/auth.slice';
import { useDispatch } from 'react-redux';

const useCustomGoogleLogin = () => {
  const dispatch = useDispatch()

  const { signIn, loaded: loginLoaded } = useGoogleLogin({
    clientId: env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || '',
    onSuccess: (response) =>
      dispatch(authSlice.actions.googleLoginSuccess({ response: response as GoogleLoginResponse })),
    onFailure: (error) => dispatch(authSlice.actions.googleLoginFailure(error)),
    cookiePolicy: 'single_host_origin',
  });

  return { signIn, loginLoaded }
}

export default useCustomGoogleLogin
