import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionsUnion } from '../../redux/ActionUnion';

export interface VisibilityState {
  sorting: Sorting;
}

export enum Sorting {
  ORDER = 'ORDER',
  VOTES = 'VOTES',
}

const initialState: VisibilityState = {
  sorting: Sorting.ORDER,
};

export const visibilitySlice = createSlice({
  name: 'visibility',
  initialState,
  reducers: {
    setSorting: (state, action: PayloadAction<Sorting>) => {
      state.sorting = action.payload;
    },
  },
});

visibilitySlice.actionTypes = visibilitySlice.actions as any;

export type BoardActions = ActionsUnion<typeof visibilitySlice.actionTypes>;
