// types
export const OPEN_COLUMN_MODAL = 'openColumnModal';
export const CLOSE_COLUMN_MODAL = 'closeColumnModal';

export const OPEN_CARD_MODAL = 'openCardModal';
export const CLOSE_CARD_MODAL = 'closeCardModal';

export const OPEN_MERGE_MODAL = 'openMergeModal';
export const CLOSE_MERGE_MODAL = 'closeMergeModal';

export const SET_CARD_MERGE_DATA = 'setCardMergeData';
export const RESET_CARD_MERGE_DATA = 'resetCardMergeData';

interface OpenColumnModalAction {
  type: typeof OPEN_COLUMN_MODAL;
  columnToRemoveId: string;
}

interface CloseColumnModalAction {
  type: typeof CLOSE_COLUMN_MODAL;
}

interface OpenCardModalAction {
  type: typeof OPEN_CARD_MODAL;
  cardToRemoveId: string;
  columnToRemoveCardId: string;
}

interface CloseCardModalAction {
  type: typeof CLOSE_CARD_MODAL;
}

interface OpenMergeModalAction {
  type: typeof OPEN_MERGE_MODAL;
  cardForMergeId: string;
  columnForMergeCardId: string;
  dragEndCardId: string;
  modalText: string;
}

interface CloseMergeModalAction {
  type: typeof CLOSE_MERGE_MODAL;
}

interface SetCardMergeDataAction {
  type: typeof SET_CARD_MERGE_DATA;
  cardForMergeId: string;
  columnForMergeCardId: string;
}

interface ResetCardMergeDataAction {
  type: typeof RESET_CARD_MERGE_DATA;
}

export type BoardPageActionTypes =
  | OpenColumnModalAction
  | CloseColumnModalAction
  | OpenCardModalAction
  | CloseCardModalAction
  | OpenMergeModalAction
  | CloseMergeModalAction
  | SetCardMergeDataAction
  | ResetCardMergeDataAction;

// actions
const openColumnModal = (columnToRemoveId: string): BoardPageActionTypes => ({
  type: OPEN_COLUMN_MODAL,
  columnToRemoveId,
});

const closeColumnModal = (): BoardPageActionTypes => ({
  type: CLOSE_COLUMN_MODAL,
});

const openCardModal = (
  columnToRemoveCardId: string,
  cardToRemoveId: string
): BoardPageActionTypes => ({
  type: OPEN_CARD_MODAL,
  cardToRemoveId,
  columnToRemoveCardId,
});

const closeCardModal = (): BoardPageActionTypes => ({
  type: CLOSE_CARD_MODAL,
});

const openMergeModal = (
  cardForMergeId: string,
  columnForMergeCardId: string,
  dragEndCardId: string,
  modalText: string
): BoardPageActionTypes => ({
  type: OPEN_MERGE_MODAL,
  cardForMergeId,
  columnForMergeCardId,
  dragEndCardId,
  modalText,
});

const closeMergeModal = (): BoardPageActionTypes => ({
  type: CLOSE_MERGE_MODAL,
});

const setCardMergeData = (
  cardForMergeId: string,
  columnForMergeCardId: string
): BoardPageActionTypes => ({
  type: SET_CARD_MERGE_DATA,
  cardForMergeId,
  columnForMergeCardId,
});

const resetCardMergeData = (): BoardPageActionTypes => ({
  type: RESET_CARD_MERGE_DATA,
});

export {
  openColumnModal,
  closeColumnModal,
  openCardModal,
  closeCardModal,
  openMergeModal,
  closeMergeModal,
  setCardMergeData,
  resetCardMergeData,
};
