import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionsUnion } from '../../redux/ActionUnion';

export enum TrackStatus {
  InProgress = 'InProgress',
  Success = 'Success',
  Failure = 'Failure',
}

export interface TrackerState {
  [trackName: string]: TrackStatus;
}

export const initialState: TrackerState = {};

export const trackerSlice = createSlice({
  name: 'tracker',
  initialState,
  reducers: {
    setInProgress: (state, action: PayloadAction<{ trackName: string }>) => {
      state[action.payload.trackName] = TrackStatus.InProgress;
    },
    setSuccess: (state, action: PayloadAction<{ trackName: string }>) => {
      state[action.payload.trackName] = TrackStatus.Success;
    },
    setFailure: (state, action: PayloadAction<{ trackName: string }>) => {
      state[action.payload.trackName] = TrackStatus.Failure;
    },
  },
});

trackerSlice.actionTypes = trackerSlice.actions as any;

export type TrackerActions = ActionsUnion<typeof trackerSlice.actionTypes>;
