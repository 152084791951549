import React from 'react';

export interface Props {

};

export const BoardHeader: React.FC<Props> = ({ children }) => {
  return (
    <div className="board__header">
      {children}
    </div>
  );
};
