import React from 'react';
import { useHistory } from 'react-router-dom';
import { translate, translationKeys } from '../../logic/translations/translations.service';
import { HeaderSettings } from '../HeaderSettings';

import { ReactComponent as ArrowLeft } from '../../assets/icons/left.svg';

import './Header.scss';

interface Props {
  settingsVisible?: boolean;
  title?: string;
  buttonBack?: boolean;
}

export const Header: React.FC<Props> = ({ settingsVisible = true, title, buttonBack = true }) => {
  const browserHistory = useHistory();
  const { backButton, boardName } = translationKeys.pages.boardPage;

  return (
    <div className="header">
      {buttonBack && (
        <button
          className="board-column__icon-btn header__settings-container--left"
          onClick={() => browserHistory.goBack()}
          title={translate(backButton)}
        >
          <ArrowLeft height="25px" width="25px" />
        </button>
      )}
      <div className="header__board-name" title={translate(boardName)}>
        {title ?? translate(boardName)}
      </div>
      {settingsVisible && <HeaderSettings />}
    </div>
  );
};
