import i18n, { Scope, TranslateOptions } from 'i18n-js';
import { en } from './en';
import { reduce } from 'lodash';
import { isObject } from 'util';

i18n.fallbacks = true;
i18n.translations = { en };
i18n.locale = 'en';

export const translate = (scope: Scope, options?: TranslateOptions) => {
  return i18n.t(scope, options);
};

export const getTranslationKeys = <T>(translations: T, path: string = ''): T => {
  if (translations) {
    return reduce(
      translations as any,
      (accumulator: any, value: any, key: string) => {
        const newPath = `${path}${!!path ? '.' : ''}${key}`;
        return {
          ...accumulator,
          [key]: isObject(value) ? getTranslationKeys(value, newPath) : newPath,
        };
      },
      {}
    );
  } else {
    return translations;
  }
};

export const translationKeys = getTranslationKeys(en);
