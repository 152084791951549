import React from 'react';
import { RouteProps, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getIsAuthenticated } from '../../logic/store/auth/auth.selectors';
import { useSelector } from 'react-redux';

interface Props extends RouteProps {
  redirectTo?: string;
}

export const AuthRoute: React.FC<Props> = ({ redirectTo = '/', ...props }) => {
  const isAuthenticated = useSelector(getIsAuthenticated);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {isAuthenticated ? (
        <Route {...props} />
      ) : (
        <Route {...props} component={() => <Redirect to={redirectTo} />} render={undefined} />
      )}
    </>
  );
};
