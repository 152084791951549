import React, { useState, createRef } from 'react';
import { useMedia } from 'react-use';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { StoreState } from '../../app/app.reducers';
import { SettingsButton } from '../SettingsButton';
import { SettingsButtonMobile } from '../SettingsButtonMobile';
import { DropdownOptions, DropdownOptionProps } from '../DropdownOptions';
import { translate, translationKeys } from '../../logic/translations/translations.service';
import { ChangeValueModal } from '../ChangeValueModal';
import { socketHandlers } from '../../common/signalR/signals';
import { moveBoardToDirectory, exportBoard } from '../../common/archive';
import { getIsAuthenticated } from '../../logic/store/auth/auth.selectors';
import { useHistory } from 'react-router-dom';
import { LoginByGoogleButton } from '../../pages/CreateBoardPage/components/LoginByGoogleButton/LoginByGoogleButton';
import { InformationModal } from '../InformationModal';
import useCustomGoogleLogin from '../../logic/hooks/useCustomGoogleLogin';

import './../Header/Header.scss';
import './HeaderSettings.scss';

const { settings } = translationKeys.pages.boardPage;
const MAX_VOTES = 50;
const MIN_VOTES = 1;

const INFO_MODAL_KEY = 'login_modal_shown';

export const HeaderSettings: React.FC = () => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const isLargeTablet = useMedia('(min-width: 768px)');
  const boardId = useSelector((state: StoreState) => state.board?.board?.id);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const [infoModalOpen, setInfoModalOpen] = useState(
    !localStorage.getItem(INFO_MODAL_KEY) && !isAuthenticated
  );
  const history = useHistory();

  const [votesEditVisible, setVotesEditVisible] = useState(false);
  const currentVotesPerUser = useSelector((state: StoreState) => state.board.board?.votesPerUser);
  const votesNumberRef = createRef<HTMLInputElement>();

  const [userNameEditVisible, setUserNameEditVisible] = useState(false);
  const currentUserName = useSelector((state: StoreState) => state.auth.user?.name);
  const userNameRef = createRef<HTMLInputElement>();

  const { signIn, loginLoaded } = useCustomGoogleLogin();

  const {
    userNameTooLong,
    provideUserName,
    votesNumberNotValid,
    valueMissing,
    valueBetween,
  } = translationKeys.messages;
  const {
    heading: loginModalHeading,
    description: loginModalDescription,
  } = translationKeys.info.loginModal;
  const { boardSaveSuccess, boardSaveFailed } = translationKeys.pages.boardPage;
  const { boardExportFailed } = translationKeys.pages.boardPage;
  const { changeVotesPerUser, changeUserName } = translationKeys.pages.boardPage.modalTexts;
  const { login, logout } = translationKeys.buttons;

  const handleUsernameChange = () => {
    const currentValue = userNameRef.current?.value.trim();
    if (boardId && currentValue) {
      if (currentValue.length < 100) {
        socketHandlers.changeUsername(boardId, currentValue);
        setUserNameEditVisible(false);
      } else {
        toast.warn(translate(userNameTooLong));
      }
    } else {
      toast.warn(translate(provideUserName));
    }
  };

  const handleChangeVotes = () => {
    const newVotesString = votesNumberRef.current?.value;
    if (newVotesString) {
      const newVotesNumber = parseInt(newVotesString);
      if (
        !boardId ||
        Number.isNaN(newVotesNumber) ||
        newVotesNumber < MIN_VOTES ||
        newVotesNumber > MAX_VOTES
      ) {
        toast.warn(translate(votesNumberNotValid));
      } else {
        socketHandlers.setAvailableVotes(boardId, newVotesNumber);
        setVotesEditVisible(false);
      }
    } else {
      toast.warn(translate(valueMissing));
    }
  };

  const handleToArchive = () => {
    history.push('/archive');
  };

  const handleSaveBoard = async () => {
    try {
      await moveBoardToDirectory(boardId, 'ROOT');
      history.push('/archive');
      toast.success(translate(boardSaveSuccess));
    } catch (error) {
      toast.error(translate(boardSaveFailed));
    }
  };

  const handleExportBoard = async () => {
    try {
      const response = await exportBoard(false, boardId);
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download',  'board.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      toast.error(translate(boardExportFailed));
    }
  };

  let options: DropdownOptionProps[] = [
    {
      label: translate(settings.setName),
      onClick: () => {
        setUserNameEditVisible(true);
        setOptionsVisible(false);
      },
    },
    {
      label: translate(settings.setVotes),
      onClick: () => {
        setVotesEditVisible(true);
        setOptionsVisible(false);
      },
    },
    {
      className: isAuthenticated ? '' : 'settings-option--disabled',
      title: translate(settings.saveBoardTooltip),
      label: translate(settings.saveBoard),
      onClick: isAuthenticated ? handleSaveBoard : signIn,
      disabled: !loginLoaded,
    },
    {
      className: isAuthenticated ? '' : 'settings-option--disabled',
      title: translate(settings.exportBoardTooltip),
      label: translate(settings.exportBoard),
      onClick: isAuthenticated ? handleExportBoard : signIn,
      disabled: !loginLoaded,
    },
  ];

  const insert = (
    array: DropdownOptionProps[],
    element: DropdownOptionProps,
    index: number
  ): DropdownOptionProps[] => [
    ...array.slice(0, index),
    element,
    ...array.slice(index, array.length),
  ];

  isAuthenticated &&
    (options = insert(
      options,
      {
        label: translate(settings.toArchive),
        onClick: handleToArchive,
      },
      2
    ));

  const SettingsTrrigger = () =>
    isLargeTablet ? (
      <SettingsButton
        onClick={() => setOptionsVisible(!optionsVisible)}
        settingsOptionsVisible={optionsVisible}
      />
    ) : (
      <SettingsButtonMobile
        onClick={() => setOptionsVisible(!optionsVisible)}
        settingsOptionsVisible={optionsVisible}
      />
    );

  const DropownSettingsList = () => (
    <DropdownOptions
      optionsList={options}
      classes={{
        containerClass: 'settings-options',
        optionClass: 'settings-option',
      }}
    />
  );

  return (
    <>
      <div className="header__settings-container--right">
        <div className="header__settings-options settings__button">
          <SettingsTrrigger />
          {optionsVisible && isLargeTablet && <DropownSettingsList />}
        </div>
        {isLargeTablet && (
          <div
            className={clsx(
              'settings__login-wrapper settings__button',
              infoModalOpen && 'settings__login-wrapper--active'
            )}
          >
            <LoginByGoogleButton
              className={'settings__login button button--yellow button--bold'}
              loggedOutButtonText={translate(login)}
              loggedInButtonText={translate(logout)}
            />
          </div>
        )}
      </div>
      {optionsVisible && !isLargeTablet &&
        <>
          <div
            className={clsx(
              'settings__login-wrapper settings__button settings__login-mobile',
              infoModalOpen && 'settings__login-wrapper--active'
            )}
          >
            <LoginByGoogleButton
              className={'settings__login button button--yellow button--bold'}
              loggedOutButtonText={translate(login)}
              loggedInButtonText={translate(logout)}
            />
          </div>
          <DropownSettingsList />
        </>}
      <ChangeValueModal
        isOpen={votesEditVisible}
        text={translate(changeVotesPerUser)}
        onClose={() => setVotesEditVisible(false)}
        onSubmit={handleChangeVotes}
      >
        <input
          autoFocus
          className="settings__modal-input"
          type="number"
          ref={votesNumberRef}
          defaultValue={currentVotesPerUser}
        />
        <p className="settings__votes-number-hint">{`${translate(
          valueBetween
        )} ${MIN_VOTES} and ${MAX_VOTES}`}</p>
      </ChangeValueModal>
      <ChangeValueModal
        isOpen={userNameEditVisible}
        text={translate(changeUserName)}
        onClose={() => setUserNameEditVisible(false)}
        onSubmit={handleUsernameChange}
      >
        <input
          autoFocus
          className="settings__modal-input"
          type="text"
          ref={userNameRef}
          defaultValue={currentUserName}
        />
      </ChangeValueModal>
      {isLargeTablet && (
        <InformationModal
          className="settings__info-modal"
          isOpen={infoModalOpen}
          heading={translate(loginModalHeading)}
          text={translate(loginModalDescription)}
          onClose={() => {
            localStorage.setItem(INFO_MODAL_KEY, 'true');
            setInfoModalOpen(false);
          }}
        />
      )}
    </>
  );
};
