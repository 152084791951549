import React, { createRef, useEffect, useReducer, useState } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { StoreState } from '../../app/app.reducers';
import { getOrderedColumns } from '../../app/app.selectors.helper';
import { socketHandlers } from '../../common/signalR/signals';
import { Board } from '../../components/Board';
import { BoardCard } from '../../components/BoardCard';
import { BoardColumn } from '../../components/BoardColumn/';
import { ConfirmationModal } from '../../components/ConfirmationModal';
import { Header } from '../../components/Header';
import { TopBar } from '../../components/TopBar';
import { VotingProgress, VotingProgressBar } from '../../components/VotingProgressBar';
import { Sorting } from '../../logic/store/visibility/visibility.slice';
import { translate, translationKeys } from '../../logic/translations/translations.service';
import { Card, Column, ColumnColors } from '../../models/api/api.models';
import {
  closeCardModal,
  closeColumnModal,
  closeMergeModal,
  openCardModal,
  openColumnModal,
  resetCardMergeData,
  setCardMergeData,
} from './BoardPage.actions';
import { boardPageReducer, boardPageState, BoardPageState, CardMergeStatus, } from './BoardPage.reducer';
import './BoardPage.scss';
import useDragEnd from './useDragEnd';
import { BoardColumnAddCard } from '../../components/BoardColumn/BoardColumnAddCard';
import { TopBanner } from '../../components/TopBanner';

interface Props {
}

const sortCards = (sorting: Sorting, votesVisibility: boolean | undefined) => {
  if (!votesVisibility) {
    return;
  }
  if (sorting === Sorting.ORDER) {
    return (a: Card, b: Card) =>
      a?.order === undefined || b?.order === undefined ? 0 : a.order - b.order;
  }
  if (sorting === Sorting.VOTES) {
    return (a: Card, b: Card) =>
      a?.votes === undefined || b?.votes === undefined ? 0 : b.votes.length - a.votes.length;
  }
};

export const BoardPage: React.FC<Props> = ({ children }) => {
  const [state, boardPageDispatch] = useReducer(boardPageReducer, boardPageState);
  const {
    cardToRemoveId,
    columnToRemoveCardId,
    columnToRemoveId,
    isCardModalOpen,
    isMergeModalOpen,
    isColumnModalOpen,
    cardForMergeId,
    columnForMergeCardId,
    cardMergeStatus,
    dragEndCardId,
    mergeModalText,
  } = state;
  const [beforeUnloadListeners, setBeforeUnloadListeners] = useState<(() => void)[]>([]);
  const [isBoardOverflown, setBoardOverflown] = useState(false);
  const [boardScrollLeft, setBoardScrollLeft] = useState(0);
  const [boardOffset, setBoardOffset] = useState(0);
  // change to `false` to display the info top banner with some message e.g. server downtime message
  const [isInfoBannerHidden, setIsInfoBannerHidden] = useState(true);

  const location = useLocation<BoardPageState>();
  const isDisabled = location.state?.isDisabled;
  const board = useSelector((state: StoreState) => state.board.board);
  const votesVisibility = useSelector((state: StoreState) => state.board.board?.votesVisible);
  const authorsVisiblity = useSelector(
    (state: StoreState) => state.board.board?.cardsAuthorsVisible
  );
  const userId = useSelector((state: StoreState) => state.auth.user?.id);

  const orderedColumns = useSelector(getOrderedColumns);

  const sorting = useSelector((state: StoreState) => state.visibility.sorting);

  const boardIdFromUrl = location.pathname.split('/').pop() || '';
  const boardIdFromState = board?.id;
  const boardId = boardIdFromUrl || boardIdFromState || '';
  const handleDragEnd = useDragEnd(boardId, boardPageDispatch);
  const boardRef = createRef<HTMLElement>();

  const {
    pages: {
      boardPage: {
        columnName,
        modalTexts: {
          columnRemoveConfirmation,
          cardRemoveConfirmation,
        },
      },
    },
    info: {
      topBanner,
    }
  } = translationKeys;

  useEffect(() => {
    boardId && socketHandlers.joinBoard(boardId);
    return () =>
      beforeUnloadListeners.forEach((listener) =>
        window.removeEventListener('beforeunload', listener)
      );
  }, [beforeUnloadListeners, boardId]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    checkOverflow();
    boardRef.current?.addEventListener('scroll', handleScroll);
  });

  const handleAddCardTop = (boardId: string, columnId: string, text?: string) =>
    socketHandlers.addCardTop(boardId, columnId, text);
  const handleAddCardBottom = (boardId: string, columnId: string, text?: string) =>
    socketHandlers.addCardBottom(boardId, columnId, text);

  const handleEditCard = (
    boardId: string,
    columnId: string,
    cardId: string,
    text: string,
    subcardId?: string
  ) => {
    socketHandlers.editCard(boardId, columnId, cardId, text, subcardId);
  };

  const removeCard = () => {
    socketHandlers.removeCard(boardId, columnToRemoveCardId, cardToRemoveId);
    boardPageDispatch(closeCardModal());
  };

  const removeColumn = () => {
    socketHandlers.removeColumn(boardId, columnToRemoveId);
    boardPageDispatch(closeColumnModal());
  };

  const openRemoveColumnModal = (columnToRemoveId: string) => {
    boardPageDispatch(openColumnModal(columnToRemoveId));
  };

  const closeRemoveColumnModal = () => {
    boardPageDispatch(closeColumnModal());
  };

  const openRemoveCardModal = (columnToRemoveCardId: string, cardToRemoveId: string) => {
    boardPageDispatch(openCardModal(columnToRemoveCardId, cardToRemoveId));
  };

  const handleAddVote = (boardId: string | undefined, columnId: string, cardId: string) => {
    boardId && socketHandlers.addVote(boardId, columnId, cardId);
  };

  const handleRemoveVote = (boardId: string | undefined, columnId: string, cardId: string) => {
    boardId && socketHandlers.removeVote(boardId, columnId, cardId);
  };

  const closeRemoveCardModal = () => {
    boardPageDispatch(closeCardModal());
  };

  const closeMergeCardModal = () => {
    boardPageDispatch(closeMergeModal());
  };

  const handleRenameColumn = (column: Column) => (newColumnName: string) => () => {
    if (boardId && column.id && column.name !== newColumnName) {
      socketHandlers.renameColumn(boardId, column.id, newColumnName);
    }
  };

  const handleChangeColumnOrder = (boardId: string, columnId: string, order: number) => {
    socketHandlers.changeColumnOrder(boardId, columnId, order);
  };

  const handleMergeCard = (boardId: string, columnId: string, cardId: string) => {
    if (cardForMergeId === cardId && columnForMergeCardId === columnId) {
      return;
    }

    if (cardMergeStatus === CardMergeStatus.DataIsNotSet) {
      boardPageDispatch(setCardMergeData(cardId, columnId));
    } else if (cardMergeStatus === CardMergeStatus.DataIsSet) {
      socketHandlers.mergeCards(boardId, columnForMergeCardId, cardForMergeId, cardId);
      boardPageDispatch(resetCardMergeData());
    }
  };

  const handleUnMergeCard = (
    boardId: string,
    columnId: string,
    cardFromId: string,
    cardId: string
  ) => {
    socketHandlers.unMergeCard(boardId, columnId, cardFromId, cardId);
    boardPageDispatch(resetCardMergeData());
  };

  const getVotingProgress = (): VotingProgress => {
    let unique: String[] = [];
    let counter = 0;
    board?.columns?.forEach((col) =>
      col.cards?.forEach((card) => {
        countCardVotes(card);
        card.subCards?.forEach(countCardVotes);
      })
    );

    function countCardVotes(card: Card) {
      counter += card.votes?.length ?? 0;
      card.votes?.forEach((v) => {
        if (unique.indexOf(v) < 0) {
          unique.push(v);
        }
      });
    }

    return {
      current: counter,
      max: unique.length * (board?.votesPerUser ?? 0),
    };
  };

  const handleStopEditing = (boardId: string | undefined, columnId: string, cardId: string) => {
    boardId && socketHandlers.stopEditing(boardId, columnId, cardId);
  };

  const handleClickCard = (boardId: string | undefined, columnId: string, cardId: string) => {
    if (boardId) {
      socketHandlers.indicateEditing(boardId, columnId, cardId);
      const listener = () => handleStopEditing(boardId, columnId, cardId);
      window.addEventListener('beforeunload', listener);
      setBeforeUnloadListeners([...beforeUnloadListeners, listener]);
    }
  };

  const handleScroll = () => {
    if (boardRef.current) {
      setBoardScrollLeft(boardRef.current.scrollLeft);
    }
  };

  const checkOverflow = () => {
    if (boardRef.current != null) {
      const isOverflown = boardRef.current.scrollWidth > boardRef.current.clientWidth;

      setBoardOverflown(isOverflown);
      setBoardOffset(boardRef.current.scrollWidth - boardRef.current.clientWidth);
    }
  };

  const handleScrollClick = (direction: string) => {
    const scrollValue = 400;

    if (boardRef.current) {
      switch (direction) {
        case 'left': {
          boardRef.current.scrollLeft = boardScrollLeft - scrollValue;
          break;
        }

        case 'right': {
          boardRef.current.scrollLeft = boardScrollLeft + scrollValue;
          break;
        }

        default:
          break;
      }
    }
  };
  return (
    <div className="board-page">
      <TopBanner
        isHidden={isInfoBannerHidden}
        title={translate(topBanner.title)}
        text={translate(topBanner.text)}
        buttonLabel={translate(topBanner.button)}
        buttonOnClick={() => setIsInfoBannerHidden(true)}
      />
      <Header title={board?.name} buttonBack={false} settingsVisible={!isDisabled} />
      <Board>
        {!isDisabled && <TopBar />}
        <Board.Header>
          <VotingProgressBar values={getVotingProgress()} votesPerUser={board?.votesPerUser} />
        </Board.Header>
        <Board.Body innerRef={boardRef}>
          <DragDropContext onDragEnd={handleDragEnd}>
            {orderedColumns?.map((column, columnIdx) => (
              <BoardColumn
                key={column.id ?? columnIdx}
                id={column.id ?? ''}
                name={column.name || `${translate(columnName)} ${column.id}`}
                color={column.color as ColumnColors}
                order={column.order}
                onAddCard={handleAddCardTop}
                onRemove={openRemoveColumnModal}
                onRename={handleRenameColumn(column)}
                onChangeOrder={handleChangeColumnOrder}
                isDisabled={isDisabled}
              >
                {column.cards
                  ?.slice()
                  .sort(sortCards(sorting, votesVisibility))
                  .map((card, idx) => (
                    <Draggable
                      key={card.id ?? idx}
                      draggableId={card.id ?? ''}
                      index={idx}
                      isDragDisabled={isDisabled}
                    >
                      {(provided, snapshot) => (
                        <BoardCard
                          innerRef={provided.innerRef}
                          draggableProps={provided.draggableProps}
                          handleProps={provided.dragHandleProps}
                          cardId={card.id ?? ''}
                          columnId={column.id ?? ''}
                          displayId={card.displayId ?? ''}
                          text={card.text ?? ''}
                          subCards={card.subCards ?? []}
                          author={card.author ?? ''}
                          votes={card.votes ?? []}
                          votesVisibility={votesVisibility}
                          authorsVisibility={authorsVisiblity}
                          color={column.color as ColumnColors}
                          isDisabled={isDisabled}
                          isBeingEdited={card.isBeingEdited || false}
                          userId={userId}
                          onEdit={handleEditCard}
                          addVote={handleAddVote}
                          removeVote={handleRemoveVote}
                          onRemove={openRemoveCardModal}
                          onMerge={handleMergeCard}
                          onUnMerge={handleUnMergeCard}
                          onCardClick={handleClickCard}
                          onStopEdit={handleStopEditing}
                          isDraggedOver={Boolean(snapshot.combineTargetFor)}
                        />
                      )}
                    </Draggable>
                  ))}
                {column.id && column.cards && column.cards.length > 2 && (
                  <BoardColumnAddCard
                    columnId={column.id}
                    onAddCard={handleAddCardBottom}
                    isBottom={true}
                  />
                )}
              </BoardColumn>
            ))}
            {isBoardOverflown && (
              <>
                {boardScrollLeft > 0 && (
                  <button
                    onClick={() => handleScrollClick('left')}
                    className="columns__scroll-button columns__scroll-button--left hide-mobile"
                    type="button"
                  >
                    <span />
                    <span />
                    <span />
                  </button>
                )}
                {boardScrollLeft < boardOffset && (
                  <button
                    onClick={() => handleScrollClick('right')}
                    className="columns__scroll-button columns__scroll-button--right hide-mobile"
                    type="button"
                  >
                    <span />
                    <span />
                    <span />
                  </button>
                )}
              </>
            )}
          </DragDropContext>
        </Board.Body>
      </Board>
      <ConfirmationModal
        text={`${translate(columnRemoveConfirmation)}`}
        isOpen={isColumnModalOpen}
        onClose={closeRemoveColumnModal}
        onSubmit={removeColumn}
        onCancel={closeRemoveColumnModal}
      />
      <ConfirmationModal
        text={`${translate(cardRemoveConfirmation)}`}
        isOpen={isCardModalOpen}
        onClose={closeRemoveCardModal}
        onSubmit={removeCard}
        onCancel={closeRemoveCardModal}
      />
      <ConfirmationModal
        text={mergeModalText}
        isOpen={isMergeModalOpen}
        onClose={closeMergeCardModal}
        onSubmit={() => {
          socketHandlers.mergeCards(boardId, columnForMergeCardId, cardForMergeId, dragEndCardId);
          boardPageDispatch(closeMergeModal());
        }}
        onCancel={closeMergeCardModal}
      />
    </div>
  );
};
