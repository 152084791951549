import React from 'react';
import { Button } from '../Button';
import './TopBanner.scss';

export interface TopBannerProps {
  isHidden?: boolean;
  title?: string;
  text: string;
  buttonLabel?: string;
  buttonOnClick?: () => void;
}

export const TopBanner: React.FC<TopBannerProps> = ({
  isHidden = false,
  title,
  text,
  buttonLabel,
  buttonOnClick,
}) => (
  <>
    {isHidden ||
      (<div className="top-banner">
        <div className="text-container">
          <span className="top-banner__title">{title}</span> {text}
        </div>
        {buttonLabel && buttonOnClick && (
          <Button className="button top-banner__button" title={buttonLabel} onClick={buttonOnClick}>{buttonLabel}</Button>
        )}
      </div>)
    }
  </>
);

