import React, { cloneElement, useState } from 'react';
import { Button } from '../Button';
import { Modal } from '../Modal';
import './ConfirmationModal.scss';

interface ConfirmationModalProps {
  isOpen: boolean;
  text: string;
  onSubmit: () => void;
  onCancel: () => void;
  onClose: () => void;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  text,
  onSubmit,
  onCancel,
  onClose,
}) => {
  return (
    <Modal className="confirmation-modal" isOpen={isOpen} onClose={onClose}>
      <aside>
        <p className="confirmation-modal__title">{text}</p>
        <div className="confirmation-modal__btns-wrapper">
          <Button className="button button--white confirmation-modal__button" onClick={onSubmit}>
            Yes
          </Button>
          <Button className="button button--white confirmation-modal__button" onClick={onCancel}>
            No
          </Button>
        </div>
      </aside>
    </Modal>
  );
};

interface UncontrolledConfirmationModalProps
  extends Omit<ConfirmationModalProps, 'isOpen' | 'onCancel' | 'onClose'>,
    Partial<Pick<ConfirmationModalProps, 'onCancel' | 'onClose'>> {
  children: React.ReactElement;
}

export const UncontrolledConfirmationModal = ({
  children,
  onClose,
  onCancel,
  ...rest
}: UncontrolledConfirmationModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOnClose = () => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  };
  const handleOnCancel = () => {
    setIsOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <>
      {cloneElement(children, {
        onClick: (e: React.DOMAttributes<unknown>['onClick']) => {
          setIsOpen(true);
          children.props.onClick?.(e);
        },
      })}
      <ConfirmationModal
        isOpen={isOpen}
        onClose={handleOnClose}
        onCancel={handleOnCancel}
        {...rest}
      />
    </>
  );
};
