import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Header } from '../../components/Header';
import { Spinner } from '../../components/Spinner';

import './ArchivePage.scss';
import {
  getArchiveDirectory,
  createDirectory,
  removeBoardFromDirectory,
  moveBoardToDirectory,
} from '../../common/archive';
import { ArchiveItem } from './components/ArchiveItem';
import { translate, translationKeys } from '../../logic/translations/translations.service';

interface Props {
  match: any;
}

interface ArchiveBoard {
  id: string;
  created: string;
  archived: string;
  name: string;
}

interface Archive {
  boards: ArchiveBoard[];
  subdirectories: {
    id: string;
    name: string;
    boards: ArchiveBoard[];
  }[];
  name: string;
  parentId: string;
}

export const ArchivePage: React.FC<Props> = ({ match }) => {
  const [archive, setArchive] = useState<Archive>();
  const [newDirectoryName, setNewDirectoryName] = useState('');
  const [selectedBoards, setSelectedBoards] = useState<string[]>([]);
  const { id } = match.params;
  const [isLoading, setIsLoading] = useState(false);

  const {
    directoryCreationSuccess,
    directoryCreationFail,
    boardRemoveSuccess,
    boardRemoveFail,
    boardsMovedSuccess,
    boardsMovedFail
  } = translationKeys.pages.archivePage;

  useEffect(() => {
    getArchiveData(id);
  }, [id]);

  const getArchiveData = (directoryId?: string) => {
    setIsLoading(true);
    getArchiveDirectory(directoryId).then((response) => {
      setArchive(response?.data);
    }).finally (() => {
      setIsLoading(false);
    })
  };

  const handleInputChange = (event: any) => {
    setNewDirectoryName(event.target.value);
  };

  const handleCreateDirectory = async () => {
    if (newDirectoryName) {
      setIsLoading(true);
      try {
        await createDirectory(newDirectoryName, id);
        toast.success(translate(directoryCreationSuccess));
      } catch (error) {
        toast.error(translate(directoryCreationFail));
      } finally {
        setNewDirectoryName('');
        getArchiveData(id);
        setIsLoading(false);
      }
    }
  };

  const handleDelete = async (boardId?: string) => {
    try {
      await removeBoardFromDirectory(boardId, id);
      toast.success(translate(boardRemoveSuccess));
    } catch (error) {
      toast.error(translate(boardRemoveFail));
    } finally {
      getArchiveData(id);
    }
  };

  const handleBoardSelection = async (id?: string) => {
    const boardIdx = selectedBoards.findIndex((board) => board === id);
    if (boardIdx < 0 && id) {
      selectedBoards.push(id);
      setSelectedBoards([...selectedBoards]);
      return;
    }

    selectedBoards.splice(boardIdx, 1);
    setSelectedBoards([...selectedBoards]);
  };

  const handleMoveBoardsToDirectory = async (directoryId?: string) => {
    try {
      if (selectedBoards.length) {
        for (const sb of selectedBoards) {
          await moveBoardToDirectory(sb, directoryId);
        }
        setSelectedBoards([]);
        toast.success(translate(boardsMovedSuccess));
      }
    } catch (error) {
      toast.error(translate(boardsMovedFail));
    } finally {
      getArchiveData(id);
    }
  };

  return (
    <div className="archive-page">
      <Header title="Saved boards" settingsVisible={false} />
      <div className="archive-page__container">
        <div className="archive-page__container__addFolder">
          <div className="input-container">
            <input name="add-directory" id="id-add-directory" className="input" value={newDirectoryName} onChange={handleInputChange} />
            <label className="label" htmlFor="id-add-directory">Name you directory</label>
          </div>
          <button className="button button--yellow" onClick={handleCreateDirectory}>Add directory</button>
        </div>
        <div className="archive-page__title">{archive?.name}</div>
        {archive?.parentId !== null && selectedBoards.length > 0 && (
          <div className="archive-page__boardContainer">
            <button onClick={() => handleMoveBoardsToDirectory(archive?.parentId)}>
              Move to parent dir
            </button>
          </div>
        )}
        {archive?.subdirectories && archive?.subdirectories.length > 0 && (
          <div className="archive-page__boardContainer">
            {isLoading && <Spinner />}
            {archive?.subdirectories?.map((directory) => {
              return (
                <ArchiveItem
                  key={directory.id}
                  id={directory.id}
                  title={directory.name}
                  isDirectory
                  isSelectedMode={selectedBoards.length > 0}
                  onMoveToDirectory={handleMoveBoardsToDirectory}
                  boardsCount={directory.boards.length}
                />
              );
            })}
          </div>
        )}
        <div className="archive-page__boardContainer">
          {isLoading && <Spinner />}
          {archive?.boards.map((board) => {
            return (
              <ArchiveItem
                key={board.id}
                title={board.name}
                id={board.id}
                onDelete={handleDelete}
                onClick={() => handleBoardSelection(board.id)}
                isSelectedMode={
                  selectedBoards.findIndex((selectedBoard) => selectedBoard === board.id) >= 0
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
