import { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

const useDetectClickOutside = (cb: () => void, eventListenerCondition?: () => boolean) => {
  const elementRef = useRef(null);

  useEffect(() => {
    if (!eventListenerCondition || eventListenerCondition()) {
      const onClick = (e: Event) => {
        const element = ReactDOM.findDOMNode(elementRef.current);
        if (element && !element.contains(e.target as Node)) {
          cb();
        }
      };
      window.addEventListener('click', onClick);
      return () => window.removeEventListener('click', onClick);
    }
  }, [cb, eventListenerCondition]);

  return elementRef;
};

export default useDetectClickOutside;
