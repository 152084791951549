import React, { useState, ChangeEvent } from 'react';
import { ReactComponent as BoardIcon } from '../../../../assets/icons/board.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/right.svg';
import { ReactComponent as FolderIcon } from '../../../../assets/icons/folder.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg';

import { useHistory } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';

import './index.scss';
import { renameArchiveDirectory } from '../../../../common/archive';
import { toast } from 'react-toastify';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { UncontrolledConfirmationModal } from '../../../../components/ConfirmationModal';

interface Props {
  title: string;
  id?: string;
  isDirectory?: boolean;
  isSelectedMode?: boolean;
  boardsCount?: number;
  onDelete?: (boardId?: string) => void;
  onDirectoryNameChange?: (name: string, id?: string) => void;
  onClick?: (id?: string, isDirectory?: boolean) => void;
  onMoveToDirectory?: (directoryId?: string) => void;
}

export const ArchiveItem: React.FC<Props> = ({
  id,
  title,
  isDirectory,
  isSelectedMode,
  onDelete,
  onClick,
  onMoveToDirectory,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(title);
  const history = useHistory();

  const { directoryNameChangeSuccess, directoryNameChangeFail } = translationKeys.pages.archivePage;

  const handleOpen = (id?: string) => {
    if (isDirectory) {
      history.push(`/archive/${id}`);
      return;
    }

    history.push(`/board/${id}`);
  };

  const handleRename = async () => {
    try {
      setEditMode(false);

      if (name === title) {
        return;
      }

      await renameArchiveDirectory(name, id);
      toast.success(translate(directoryNameChangeSuccess));
    } catch (error) {
      toast.error(translate(directoryNameChangeFail));
      setName(title);
    }
  };

  const handleDirectoryChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setName(e.target.value);
  };

  const handleOnClick = () => {
    onClick && onClick(id, isDirectory);
  };

  const handleOnDelete = () => {
    onDelete && onDelete(id);
  };

  return (
    <div
      className="archive-item"
      onClick={handleOnClick}
      style={{ cursor: isDirectory ? 'arrow' : 'pointer' }}
    >
      <div className="archive-item__container">
        {isDirectory ? (
          <FolderIcon onClick={() => handleOpen(id)} />
        ) : (
          <BoardIcon onClick={() => handleOpen(id)} />
        )}
        {editMode ? (
          <TextareaAutosize
            value={name}
            onBlur={handleRename}
            onChange={handleDirectoryChange}
            autoFocus
          />
        ) : (
          <div
            className="archive-item__container__text"
            onClick={() => isDirectory && setEditMode(true)}
          >
            {name}
          </div>
        )}
      </div>
      <div className="archive-item__container" style={{ justifyContent: 'flex-end' }}>
        {isDirectory && isSelectedMode ? (
          <PlusIcon onClick={() => onMoveToDirectory && onMoveToDirectory(id)} />
        ) : (
          <>
            {isSelectedMode ? (
              <CheckIcon />
            ) : (
              <>
                {!isDirectory && (
                  <div
                    className={'archive-item__trash-wrapper'}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <UncontrolledConfirmationModal
                      text={'Are you sure you want to remove this board?'}
                      onSubmit={handleOnDelete}
                    >
                      <TrashIcon />
                    </UncontrolledConfirmationModal>
                  </div>
                )}
                <ArrowRightIcon onClick={() => handleOpen(id)} />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
