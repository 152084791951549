import React, { useState, useEffect } from "react";
import ReactModal from 'react-modal';
import clsx from "clsx";
import './Modal.scss';

type Props = {
  activator?(openModal: () => void): void;
  className?: string;
  overlayStyle?: React.CSSProperties;
  isOpen?: boolean;
  onClose?: () => void;
}

ReactModal.setAppElement('#root');

export const Modal: React.FC<Props> = ({
  isOpen = false,
  onClose,
  activator: renderActivator,
  children,
  className,
  overlayStyle = {
    position: 'fixed',
    zIndex: 9000,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    overflow: 'scroll',
  },
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => setModalOpen(isOpen), [isOpen]);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleClose = () => {
    closeModal();
    
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      {renderActivator && renderActivator(openModal)}
      <ReactModal
        isOpen={isModalOpen}
        className={clsx('modal', className && className)}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={handleClose}
        style={{
          overlay: overlayStyle,
        }}
      >
        <span className="modal__close" onClick={handleClose}>&#10005;</span>
        {children}
      </ReactModal>
    </>
  );
};
