import React, { useState } from 'react';
import { useMedia } from 'react-use';
import clsx from 'clsx';

import { Button } from '../Button';
import { Timer } from '../Timer';
import { DropdownOptions } from '../DropdownOptions';

import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right.svg';

import { socketHandlers } from '../../common/signalR/signals';
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from '../../app/app.reducers';
import { translate, translationKeys } from '../../logic/translations/translations.service';
import { Sorting, visibilitySlice } from '../../logic/store/visibility/visibility.slice';

import './TopBar.scss';
import { timerSlice } from '../../logic/store/timer/timer.slice';

const { topBar, newColumnName } = translationKeys.pages.boardPage;
const { settings } = translationKeys.pages.boardPage;
export const TopBar: React.FC = () => {
  const dispatch = useDispatch();
  const isWide = useMedia('(min-width: 1000px)');
  const [optionsVisible, setOptionsVisible] = useState(false);
  const boardId = useSelector((state: StoreState) => state.board.board?.id);
  const timer = useSelector((state: StoreState) => state.timer);
  const votesVisibility = useSelector((state: StoreState) => state.board.board?.votesVisible);
  const authorsVisibility = useSelector(
    (state: StoreState) => state.board.board?.cardsAuthorsVisible
  );
  const { sorting: currentSorting } = useSelector((state: StoreState) => state.visibility);

  const votesVisibilityText = votesVisibility
    ? translate(topBar.hideVotes)
    : translate(topBar.showVotes);

  const authorsVisibilityText = authorsVisibility
    ? translate(topBar.hideUsers)
    : translate(topBar.showUsers);

  const addColumn = () => socketHandlers.addColumn(boardId ?? '', translate(newColumnName));

  const changeVotesVisibility = () =>
    boardId && socketHandlers.setVotesVisibility(boardId, !votesVisibility);

  const changeAuthorsVisibility = () =>
    boardId && socketHandlers.setCardsAuthorsVisibility(boardId, !authorsVisibility);

  const isSortingByOrder = currentSorting === Sorting.ORDER;
  const toggleSorting = () =>
    dispatch(visibilitySlice.actions.setSorting(isSortingByOrder ? Sorting.VOTES : Sorting.ORDER));

  const sortingText = isSortingByOrder
    ? translate(topBar.sortBytVotes)
    : translate(topBar.sortBytOrder);
  const showTimer = () => dispatch(timerSlice.actions.setVisibility(true));

  const sortingDisabled = !votesVisibility;

  const options: any = [
    {
      label: sortingText,
      onClick: toggleSorting,
    },
    {
      label: votesVisibilityText,
      onClick: changeVotesVisibility,
    },
    {
      label: authorsVisibilityText,
      onClick: changeAuthorsVisibility,
    },
    {
      label: translate(topBar.addColumn),
      onClick: addColumn,
    },
  ];

  !timer.visibility &&
    options.push({
      label: translate(settings.showTimer),
      onClick: showTimer,
    });

  return (
    <>
      {(isWide || timer.visibility) && (
        <div className="top-bar">
          {isWide && (
            <div className="top-bar__container top-bar__container--left">
              <Button
                className="button top-bar__button top-bar__button--sort button--white"
                onClick={toggleSorting}
                disabled={sortingDisabled}
                title={sortingText}
              >
                {sortingText}
                <div className="top-bar__icons">
                  <LeftIcon />
                  <RightIcon />
                </div>
              </Button>
              <Button
                className="button top-bar__button button--white"
                title={votesVisibilityText}
                onClick={changeVotesVisibility}
              >
                {votesVisibilityText}
              </Button>
              <Button
                className="button top-bar__button button--white"
                title={authorsVisibilityText}
                onClick={changeAuthorsVisibility}
              >
                {authorsVisibilityText}
              </Button>
              {!timer.visibility && (
                <Button
                  className="button top-bar__button button--white"
                  title={translate(settings.showTimer)}
                  onClick={showTimer}
                >
                  {translate(settings.showTimer)}
                </Button>
              )}
            </div>
          )}
          {timer.visibility && (
            <div className="top-bar__container top-bar__container--middle">
              <Timer />
            </div>
          )}
          {isWide && (
            <div className="top-bar__container top-bar__container--right">
              <Button
                className="button top-bar__button button--white"
                title={translate(topBar.addColumn)}
                onClick={addColumn}
              >
                + {translate(topBar.addColumn)}
              </Button>
            </div>
          )}
        </div>
      )}
      {!isWide && (
        <>
          <Button
            className={clsx('topbar-button--settings', optionsVisible ? 'open' : 'closed')}
            onClick={() => setOptionsVisible(!optionsVisible)}
          >
            {optionsVisible ? (
              <>{translate(topBar.hideOptions)}</>
            ) : (
              <>{translate(topBar.showOptions)}</>
            )}
            <ArrowDown />
          </Button>
          {optionsVisible && (
            <DropdownOptions
              optionsList={options}
              classes={{
                containerClass: 'top-bar-options',
                optionClass: 'top-bar-option',
              }}
            />
          )}
        </>
      )}
    </>
  );
};
