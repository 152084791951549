import React, { FunctionComponent } from 'react';
import './HistoryPage.scss';

interface OwnProps {}

type Props = OwnProps;

export const HistoryPage: FunctionComponent<Props> = (props) => {
  return (
    <div className="history-page">
      HistoryPage
    </div>
  );
};
