import { axiosAuthenticatedClient } from '../../logic/axios/axiosAuthenticatedClient';

export const getArchiveDirectory = async (directoryId?: string) => {
  return await axiosAuthenticatedClient().get('/api/Archive/GetDirectory', {
    params: {
      directoryId: directoryId ?? 'ROOT',
    },
  });
};

export const createDirectory = async (name: string, parentId?: string) => {
  return await axiosAuthenticatedClient().post('/api/Archive/CreateDirectory', {
    name,
    parentId,
  });
};

export const moveBoardToDirectory = async (boardId?: string, directoryId?: string) => {
  return await axiosAuthenticatedClient().get('/api/Archive/MoveBoardToDirectory', {
    params: {
      boardId,
      directoryId,
    },
  });
};

export const exportBoard = async (fromArchive?: boolean, boardId?: string) => {
  return await axiosAuthenticatedClient(true).get('/api/Export/Xlsx', {
    params: {
      fromArchive,
      boardId,
    },
  });
};

export const removeBoardFromDirectory = async (boardId?: string, directoryId?: string) => {
  return await axiosAuthenticatedClient().delete('/api/Archive/RemoveBoardFromDirectory', {
    params: {
      boardId,
      directoryId: directoryId ?? 'ROOT',
    },
  });
};

export const renameArchiveDirectory = async (name: string, directoryId?: string) => {
  return await axiosAuthenticatedClient().get('/api/Archive/RenameDirectory', {
    params: {
      directoryId,
      name,
    },
  });
};
