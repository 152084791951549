const pad = (num: number): string =>
  num.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });

export function formatTime(seconds: number): string {
  if (!seconds) {
    return '00:00';
  }

  const hours = Math.floor(seconds / (60 * 60));
  if (hours !== 0) {
    seconds = seconds % (60 * 60);
  }
  const minutes = Math.floor(seconds / 60);

  return `${hours ? hours + ':' : ''}${pad(minutes)}:${pad(Math.round(seconds) % 60)}`;
}

export function getSecondsFromHHMMSS(value: string): number {
  if (!value) return 0;

  const length = value.split(':').length;
  const [val1, val2, val3] = value
    .split(':')
    .map(Number)
    .map((value) => (isNaN(value) ? 0 : value));

  switch (length) {
    case 1:
      return val1;

    case 2:
      return val1 * 60 + val2;

    case 3:
      return val1 * 60 * 60 + val2 * 60 + val3;

    default:
      return 0;
  }
}
