import React from 'react';
import { Button, ButtonProps } from '../Button';

import { ReactComponent as Hamburger } from '../../assets/icons/hamburger-menu.svg';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import './SettingsButtonMobile.scss';

export interface Props extends ButtonProps {
  settingsOptionsVisible?: boolean;
}

export const SettingsButtonMobile: React.FC<Props> = ({
  onClick,
  settingsOptionsVisible,
  ...rest
}) => (
  <Button className="button-settings--mobile" onClick={onClick} {...rest}>
    <span className="settings-icon">
      {settingsOptionsVisible ? <Close /> : <Hamburger />}
    </span>
  </Button>
);
