import { DropResult } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getOrderedColumns } from '../../app/app.selectors.helper';
import { socketHandlers } from '../../common/signalR/signals';
import { boardSlice } from '../../logic/store/board/board.slice';
import { translate, translationKeys } from '../../logic/translations/translations.service';
import { Column } from '../../models/api/api.models';
import { Undefineable } from '../../models/Undefineable';
import { BoardPageActionTypes, openMergeModal } from './BoardPage.actions';

const findColumn = (columns: Undefineable<Column[]>, colId: string) =>
  columns?.find((col) => col.id === colId);

const findCardDisplayId = (columns: Undefineable<Column[]>, colId: string, cardId: string) =>
  findColumn(columns, colId)?.cards?.find((card) => card.id === cardId)?.displayId;

const useDragEnd = (boardId: string, boardPageDispatch: React.Dispatch<BoardPageActionTypes>) => {
  const dispatch = useDispatch();
  const orderedColumns = useSelector(getOrderedColumns);
  const { errorMergeDifferentColumn } = translationKeys.errors.merge;
  const { mergeConfirmation } = translationKeys.pages.boardPage.modalTexts;

  return (result: DropResult) => {
    const { destination, source, draggableId, combine } = result;
    const isCombiningCardsFromDifferentColumns =
      combine && source.droppableId !== combine.droppableId;

    if (isCombiningCardsFromDifferentColumns) {
      toast.error(translate(errorMergeDifferentColumn));
      return;
    }

    if (combine) {
      const modalText = translate(mergeConfirmation, {
        card1: findCardDisplayId(orderedColumns, combine.droppableId, draggableId),
        card2: findCardDisplayId(orderedColumns, combine.droppableId, combine.draggableId),
      });
      boardPageDispatch(
        openMergeModal(draggableId, combine.droppableId, combine.draggableId, modalText)
      );
      return;
    }

    if (!destination || !source) {
      return;
    }

    //If droppableId is the same change order of cards.
    if (destination.droppableId === source.droppableId) {
      //Change card order locally.
      dispatch(boardSlice.actions.setCardOrderLocally({ destination, source }));

      //Send order change to server.
      socketHandlers.setCardOrder(boardId, source.droppableId, draggableId, destination.index);

      return;
    }

    //Changes is done locally to prevent skipping effect of the UI when it waits for server action.
    dispatch(boardSlice.actions.changeCardColumnLocally({ destination, source }));

    //Change order and change columns, user dropped card into another column.
    socketHandlers.changeCardColumn(
      boardId,
      source.droppableId,
      draggableId,
      destination.droppableId,
      destination.index
    );
  };
};

export default useDragEnd;
