import React from 'react';
import {translate, translationKeys} from "../../logic/translations/translations.service";
import { useSelector } from "react-redux";
import { StoreState } from "../../app/app.reducers";
import clsx from "clsx";

interface Props {
  columnId: string
  onAddCard: (boardId: string, columnId: string, text?: string) => void;
  isBottom?: boolean
}

export const BoardColumnAddCard: React.FC<Props> = ({
  columnId,
  onAddCard,
  isBottom,
}) => {
  const boardId = useSelector((state: StoreState) => state.board.board?.id);
  const { addCard } = translationKeys.pages.boardPage.columnSettings;

  return (
    <div className={clsx('board-column__btn-wrapper', isBottom && 'board-column__btn-bottom')}>
      <button
        onClick={() => boardId && onAddCard(boardId, columnId, '')}
        title={translate(addCard)}
        className="board-column__btn"
      >
        + {translate(addCard)}
      </button>
    </div>
  );
};
