import React from 'react';
import './Footer.scss';

import { ReactComponent as BoldareWhite } from '../../assets/images/boldare-white.svg';

export const Footer = () => (
  <footer className="footer">
    <div className="footer__container">
      <a href="https://boldare.com" rel="noopener noreferrer" target="_blank">
        <BoldareWhite width="136px" height="16px" />
      </a>
      <p>The digital product design & development company.</p>
    </div>
    <div className="footer__container __space">
      <a href="https://boldare.com/services/" className="text__bold" rel="noopener noreferrer" target="_blank">
        Services
      </a>
      <a href="https://boldare.com/career/" className="text__bold" rel="noopener noreferrer" target="_blank">
        Career
      </a>
      <a href="/policy" className="text__bold">
        Privacy policy
      </a>
      <a href="/rules" className="text__bold">
        Rules
      </a>
    </div>
    <div className="footer__container">
      <p>Help us to improve Boldare Boards for you! If you’ve found a&nbsp;bug or have an idea on how to enhance something - let us know: <a href="mailto:help.boards@boldare.com" className="text__bold">help.boards@boldare.com</a></p>
    </div>
  </footer>
);
