import React from 'react';
import { BoardHeader, Props as BoardHeaderProps } from './BoardHeader';
import { BoardBody, Props as BoardBodyProps } from './BoardBody';
import './Board.scss';

interface Props {

};

interface BoardComposition {
  Header: React.FC<BoardHeaderProps>;
  Body: React.FC<BoardBodyProps>
}

export const Board: React.FC<Props> & BoardComposition = ({ children }) => {
  return (
    <div className="board">
      {children}
    </div>
  );
};

Board.Header = BoardHeader;
Board.Body = BoardBody;
