import React from 'react';
import { useGoogleLogout } from 'react-google-login';
import { useSelector, useDispatch } from 'react-redux';
import { getIsAuthenticated } from '../../../../logic/store/auth/auth.selectors';
import { Button } from '../../../../components/Button';
import { env } from '../../../../logic/env/env';
import { authSlice } from '../../../../logic/store/auth/auth.slice';
import useCustomGoogleLogin from '../../../../logic/hooks/useCustomGoogleLogin';

interface LoginByGoogleButtonProps {
  className?: string;
  loggedInButtonText: string;
  loggedOutButtonText: string;
}

export const LoginByGoogleButton: React.FC<LoginByGoogleButtonProps> = ({
  className,
  loggedInButtonText,
  loggedOutButtonText,
}) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);

  const { signIn, loginLoaded } = useCustomGoogleLogin();

  const { signOut, loaded: logoutLoaded } = useGoogleLogout({
    clientId: env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || '',
    onLogoutSuccess: () => dispatch(authSlice.actions.googleLogoutSuccess()),
    onFailure: () => dispatch(authSlice.actions.googleLogoutFailure()),
  });

  const inProgress = !loginLoaded || !logoutLoaded;

  return (
    <>
      {isAuthenticated ? (
        <Button className={className} onClick={signOut}>
          <i className="fas fa-sign-out-alt" />
          {` ${loggedInButtonText}`}
        </Button>
      ) : (
        <Button className={className} onClick={signIn} disabled={inProgress}>
          <i className={'fas fa-sign-in-alt'} />
          {` ${loggedOutButtonText}`}
        </Button>
      )}
    </>
  );
};
