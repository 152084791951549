/* eslint-disable no-console */
import * as signalR from '@microsoft/signalr';
import { fromSignalObservable } from './SignalObservable';
import { Observable } from 'rxjs';

import { env } from '../../logic/env/env';
import { CALLBACK } from '../../components/WebsocketHandler/CallbackTypes';
import { toast } from 'react-toastify';

class SignalRConnection {
  connection: signalR.HubConnection;
  private static instance: SignalRConnection | null = null;
  private observables: Observable<any>[];

  static getInstance = () =>
    SignalRConnection.instance === null ? new SignalRConnection() : SignalRConnection.instance;

  constructor(url: string = env.REACT_APP_API_URL || '') {
    this.connection = new signalR.HubConnectionBuilder().withUrl(`${url}/boardHub`).build();
    this.connection.onclose(() => {
      this.reconnect();
    });
  }

  start = async () => {
    this.connection
      .start()
      .then(() => {
        this.observables.forEach((observable) => observable.subscribe());
      })
      .catch((err: signalR.HttpError) => {
        if (err.statusCode === 404) {
          // console.error('Connection failed', err.message);
          //@TODO: logic
        } else {
          // console.error('Connection failed', err.message);
          toast.error('Failed to connect to the server')
          this.reconnect();
        }
      });
  };

  setObservables = (observables: Observable<any>[]) => {
    this.observables = observables;
  };

  clearObservables = () => {
    this.observables = [];
  };

  signalObservable = <T>(method: CALLBACK) => fromSignalObservable<T>(this.connection, method);

  invoke = (method: string, ...params: any[]) => {
    if (this.connection.state === signalR.HubConnectionState.Connected) {
      this.connection
        .invoke<void>(method, ...params)
        .catch((error: signalR.AbortError) => console.error(error.message));
    } else {
      setTimeout(() => {
        this.invoke(method, ...params);
      }, 300);
    }
  };

  private reconnect = () => {
    setTimeout(() => {
      this.start();
    }, 6000);
  };
}

export const connection = SignalRConnection.getInstance();
