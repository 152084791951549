import React from 'react';

export interface DropdownOptionsProps {
  classes: { containerClass?: string; optionClass?: string };
  optionsList: DropdownOptionProps[];
}

export const DropdownOptions: React.FC<DropdownOptionsProps> = ({
  optionsList,
  classes,
}) => {
  return (
    <div className={classes.containerClass}>
      {optionsList.map((option, index) => (
        <DropdownOption
          key={index}
          className={`${classes.optionClass || ''} ${option.className || ''}`}
          title={option.title}
          label={option.label}
          onClick={option.onClick}
          disabled={option.disabled}
        />
      ))}
    </div>
  );
};

export interface DropdownOptionProps {
  label: string;
  onClick?: () => void;
  className?: string;
  title?: string;
  disabled?: boolean;
}

export const DropdownOption: React.FC<DropdownOptionProps> = ({
  label,
  className = '',
  title = '',
  onClick,
  disabled=false,
}) => {
  return <button className={className} title={title} onClick={onClick} disabled={disabled}>{label}</button>;
};
