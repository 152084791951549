import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { translate, translationKeys } from '../../logic/translations/translations.service';
import clsx from 'clsx';

import { Button } from '../Button';
import { formatTime } from './Timer.logic';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/minus.svg';
import { ReactComponent as TimeOffIcon } from '../../assets/icons/time-off-second.svg';
import { ReactComponent as PauseIcon } from '../../assets/icons/pause.svg';
import { ReactComponent as StopIcon } from '../../assets/icons/stop.svg';

import { StoreState } from '../../app/app.reducers';
import { TimerStates, timerSlice } from '../../logic/store/timer/timer.slice';
import { socketHandlers } from '../../common/signalR/signals';

import TimerInput from './TimerInput';
import './timer.scss';

const INITIAL_TIME_SECONDS = 5 * 60;
const TIME_INCREMENT_SECONDS = 30;
const TIME_LEFT_WARNING_SECONDS = 30;

const PLAY_BUTTON_CODE = 0x25b6;

export const Timer: React.FC = () => {
  const dispatch = useDispatch();

  const boardId = useSelector((state: StoreState) => state.board.board?.id);
  const { state: timerState, secondsLeft } = useSelector((state: StoreState) => state.timer);
  const [timerSeconds, setTimerSeconds] = useState(INITIAL_TIME_SECONDS);
  const displaySeconds =
    timerState === TimerStates.STOPPED ? timerSeconds : secondsLeft ?? INITIAL_TIME_SECONDS;

  const [isTimerInputActive, setTimerInputActive] = useState(false);

  const {
    clock,
    pauseButton,
    startButton,
    hideButton,
    stopButton,
  } = translationKeys.pages.boardPage.timer;

  const increaseTimer = () => {
    const seconds = displaySeconds + TIME_INCREMENT_SECONDS;
    setTimerSeconds(seconds);
    changeTimer(seconds);
  };

  const decreaseTimer = () => {
    const seconds =
      displaySeconds < TIME_INCREMENT_SECONDS ? 0 : displaySeconds - TIME_INCREMENT_SECONDS;
    setTimerSeconds(seconds);
    changeTimer(seconds);
  };

  const changeTimer = (seconds: number) => {
    if (boardId && timerState !== TimerStates.STOPPED) {
      socketHandlers.changeTimer(boardId, seconds);
    }
  };

  const displayTime = () => formatTime(displaySeconds);

  const playIcon = String.fromCharCode(PLAY_BUTTON_CODE);

  const isStopped = timerState === TimerStates.STOPPED;

  const timerCounts = timerState === TimerStates.COUNTS;

  const stopTimer = () => {
    if (boardId && timerState !== TimerStates.STOPPED) {
      socketHandlers.stopTimer(boardId);
      setTimerSeconds(INITIAL_TIME_SECONDS);
    }
  };

  const changeTimerState = () => {
    if (!boardId) {
      return;
    }
    switch (timerState) {
      case TimerStates.STOPPED:
        socketHandlers.startTimer(boardId, timerSeconds);
        break;
      case TimerStates.COUNTS:
        socketHandlers.pauseTimer(boardId);
        break;
      case TimerStates.PASUED:
        socketHandlers.resumeTimer(boardId);
        break;
    }
  };

  const hideTimer = () => dispatch(timerSlice.actions.setVisibility(false));

  const handleTimerInputBlur = (seconds: number) => {
    setTimerSeconds(seconds);
    changeTimer(seconds);
  };

  const timeWarning =
    timerState === TimerStates.COUNTS && (secondsLeft ?? 0) < TIME_LEFT_WARNING_SECONDS;

  return (
    <div className="timer">
      <Button onClick={decreaseTimer} className="button timer__button button--white">
        <MinusIcon />
      </Button>
      <Button onClick={increaseTimer} className="button timer__button button--white">
        <PlusIcon />
      </Button>

      {isTimerInputActive ? (
        <TimerInput
          displayValue={displayTime()}
          toggleActiveInput={() => setTimerInputActive(false)}
          onBlur={handleTimerInputBlur}
        />
      ) : (
        <div
          className={clsx('timer__time', timeWarning ? 'timer__time--warning' : '')}
          title={translate(clock)}
          onClick={() => setTimerInputActive(true)}
        >
          {displayTime()}
        </div>
      )}
      <Button
        className="button timer__button button--white"
        title={timerCounts ? translate(pauseButton) : translate(startButton)}
        onClick={changeTimerState}
      >
        {timerCounts ? <PauseIcon /> : <span className="timer__button--play">{playIcon}</span>}
      </Button>
      {isStopped && (
        <Button
          className="button timer__button button--white"
          title={translate(hideButton)}
          onClick={hideTimer}
        >
          <TimeOffIcon />
        </Button>
      )}
      {isStopped || (
        <Button
          className="button timer__button button--white"
          title={translate(stopButton)}
          onClick={stopTimer}
        >
          <StopIcon />
        </Button>
      )}
    </div>
  );
};
