import { StoreState } from '../../../app/app.reducers';
import { Undefineable } from '../../../models/Undefineable';
import { isTokenValid } from './auth.logic';

export const getToken = (state: StoreState): Undefineable<string> => state.auth.token?.idToken;

export const getTokenExpiresAt = (state: StoreState): Undefineable<number> =>
  state.auth.token?.expiresAt;

export const getIsAuthenticated = (state: StoreState): boolean =>
  isTokenValid(Date.now(), getTokenExpiresAt(state));

export const getUser = (state: StoreState) => state.auth.user;
