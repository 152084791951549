import React, { useRef, useEffect, useState } from 'react';
import { Button } from '../../components/Button';
import { LoginByGoogleButton } from './components/LoginByGoogleButton/LoginByGoogleButton';
import { translate, translationKeys } from '../../logic/translations/translations.service';
import { socketHandlers } from '../../common/signalR/signals';
import { useHistory } from 'react-router-dom';
import { connection } from '../../common/signalR';
import { toast } from 'react-toastify';
import { CALLBACK } from '../../components/WebsocketHandler/CallbackTypes';

import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';

import './CreateBoardPage.scss';
import { useSelector } from 'react-redux';
import { getIsAuthenticated } from '../../logic/store/auth/auth.selectors';
import { Spinner } from '../../components/Spinner';

const { createBoardPage } = translationKeys.pages;
const { votesNumberNotValid } = translationKeys.messages;
const MAX_VOTES = 50;
const MIN_VOTES = 1;

export const CreateBoardPage: React.FC = () => {
  const boardNameRef = useRef<HTMLInputElement>(null);
  const maxVotesRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const isAuthenticated = useSelector(getIsAuthenticated);

  const { login, logout } = translationKeys.buttons;

  const onSubmit = () => {
    const votes = maxVotesRef.current?.value;
    const name = boardNameRef.current?.value;
    if (!votes || !name) {
      toast.warn(translate(createBoardPage.validations.missingFields));
      return;
    }
    const votesNumber = parseInt(votes);
    if (Number.isNaN(votesNumber)) {
      toast.warn(translate(createBoardPage.validations.missingFields));
      return;
    }

    if (Number.isNaN(votesNumber) || votesNumber < MIN_VOTES || votesNumber > MAX_VOTES) {
      toast.error(translate(votesNumberNotValid));
      return;
    }

    socketHandlers.createBoard({
      name: name,
      maxVotes: votesNumber,
    });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const boardNameFromQuery = urlParams.get('boardName');
    if (boardNameRef.current && boardNameFromQuery) {
      boardNameRef.current.value = boardNameFromQuery;
      onSubmit();
    } else {
      setIsLoading(false);
    }
  }, []);

  connection.connection.on(CALLBACK.BOARD_CREATED, (data) => {
    history.push('/board/' + data.id);
  });

  return (
    <div className="entry-page">
      {isLoading && <Spinner />}
      <div className="entry-page__box">
        <h1 className="entry-page__title">{translate(createBoardPage.createNewRetroBoard)}</h1>
        <div className="field">
          <div className="input-container">
            <input
              ref={boardNameRef}
              className="input"
              type="text"
              id="board-name"
              maxLength={50}
              autoFocus
              required
            />
            <label className="label" htmlFor="board-name">
              {translate(createBoardPage.fields.boardName)}
            </label>
          </div>
        </div>
        <div className="field">
          <div className="input-container">
            <div className="input-number-wrapper">
              <input
                ref={maxVotesRef}
                className="input input-number"
                type="number"
                id="id-max-votes"
                name="max-votes"
                min="1"
                max="50"
                required
                defaultValue={4}
                pattern="[0-9]{2}"
              />
              <label className="label" htmlFor="id-max-votes">
                {translate(createBoardPage.fields.maxVotes)}
              </label>
              <div className="add-vote">
                <ArrowDown />
              </div>
              <div className="remove-vote">
                <ArrowDown />
              </div>
            </div>
          </div>
        </div>

        <Button className="entry-page__btn--create button button--white" onClick={onSubmit}>
          {translate(createBoardPage.buttons.createBoard)}
        </Button>
      </div>
      {isAuthenticated && (
        <div className="entry-page__links">
          <a href="/archive">Saved Boards</a>
        </div>
      )}
      <LoginByGoogleButton
        className={'button entry-page__btn--login button--medium button--yellow button--bold'}
        loggedOutButtonText={translate(login)}
        loggedInButtonText={translate(logout)}
      />
    </div>
  );
};
