import React, { useState } from 'react';
import useDetectClickOutside from '../../logic/hooks/useDetectClickOutside';
import { translate, translationKeys } from '../../logic/translations/translations.service';
import { ColumnColors } from '../../models/api/api.models';
import './ColorPicker.scss';

const AVAILABLE_COLORS = ['red', 'blue', 'violet', 'marine', 'yellow'] as const;

interface Props {
  pickedColor: typeof AVAILABLE_COLORS[number];
  changeColor: (color: ColumnColors) => void;
}

export const ColorPicker: React.FC<Props> = ({ pickedColor, changeColor }) => {
  const [pickerVisible, setPickerVisible] = useState(false);
  const containerRef = useDetectClickOutside(
    () => setPickerVisible(false),
    () => pickerVisible
  );
  const { changeCardColor } = translationKeys.pages.boardPage.columnSettings;

  const onColorClick = (color: ColumnColors) => {
    changeColor(color);
    setPickerVisible(false);
  };

  return (
    <div className="color-picker-container" ref={containerRef}>
      <button
        className={`color-square color-square--${pickedColor}`}
        title={translate(changeCardColor)}
        onClick={() => setPickerVisible(true)}
      />
      {pickerVisible && (
        <div className="color-picker">
          {AVAILABLE_COLORS.map((color, index) => (
            <button
              key={index}
              className={`color-square color-square--${color}`}
              onClick={() => onColorClick(color)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
