import { Registry } from '../../../models/api/api.models';

export const replaceRegistryId = <T>(
  registry: Registry<T>,
  oldId: string,
  newId: string
): Registry<T> => {
  const entries = Object.entries(registry);
  const newEntries = entries.map(([id, value]) => {
    return id === oldId ? [newId, value] : [id, value];
  });
  return Object.fromEntries(newEntries);
};
