import React from 'react';
import { AppRouter } from './app.routes';
import { Provider } from 'react-redux';
import { store } from './app.store';
import { NotificationToast } from '../components/NotificationToast/NotificationToast';
import { WebsocketHandler } from '../components/WebsocketHandler';

export const App: React.FC = () => {
  return (
    <Provider store={store}>
      <WebsocketHandler />
      <div className={"app-wrapper"}>
        <AppRouter />
      </div>
      <NotificationToast />
    </Provider>
  );
};
