import { env } from '../env/env';
import { createAxios, getCommonHeaders, getAuthenticationHeaders } from './axios';

export const axiosAuthenticatedClient = (isFileExport?: boolean) =>
  createAxios({
    ...(isFileExport ? { responseType: 'blob' } : ''),
    baseURL: env.REACT_APP_API_URL,
    headers: {
      ...getCommonHeaders(),
      ...getAuthenticationHeaders(),
    },
  });
