import React from 'react';
import { Button, ButtonProps } from '../Button';
import { translate, translationKeys } from '../../logic/translations/translations.service';
import clsx from 'clsx';

import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';

const { settingsButton } = translationKeys.pages.boardPage.settings;

export interface SettingsButtonProps extends ButtonProps {
  settingsOptionsVisible?: boolean;
}

export const SettingsButton: React.FC<SettingsButtonProps> = ({
  onClick,
  settingsOptionsVisible,
  ...rest
}) => (

  <Button
    className="button button-settings button--yellow"
    title={translate(settingsButton)}
    onClick={onClick}
    {...rest}
  >
    {translate(settingsButton)}
    <span className={clsx('arrow-icon', settingsOptionsVisible ? 'open' : 'closed')}>
      <ArrowDown />
    </span>
  </Button>
);
