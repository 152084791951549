import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionsUnion } from '../../redux/ActionUnion';
import { Undefineable } from '../../../models/Undefineable';
import { GoogleLoginResponse } from 'react-google-login';
import { Token, User } from './auth.api.models';
import { Registry } from '../../../models/api/api.models';
import { UserRejoined } from '../../../components/WebsocketHandler/CallbackTypes';

export interface AuthState {
  token?: Token;
  user?: User;
}

const initialState: AuthState = {
  token: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: () => initialState,
    setToken: (state, action: PayloadAction<{ token: Undefineable<Token> }>) => {
      state.token = action.payload.token;
    },
    setUser: (state, action: PayloadAction<{ user: User }>) => {
      state.user = action.payload.user;
    },
    setUserName: (state, action: PayloadAction<Registry<string>>) => {
      if (state.user?.id) {
        state.user.name = action.payload[state.user.id];
      }
    },
    setUserId: (state, action: PayloadAction<UserRejoined>) => {
      if (state.user && state.user.id === action.payload.oldUserId) {
        state.user.id = action.payload.newUserId;
      }
    },
    googleLoginSuccess: (_, _action: PayloadAction<{ response: GoogleLoginResponse }>) => {},
    googleLoginFailure: (_, _action: PayloadAction<{ error: any }>) => {
      console.log(_action.payload);
    },
    googleLogoutSuccess: () => {},
    googleLogoutFailure: () => {},
  },
});

authSlice.actionTypes = authSlice.actions as any;

export type AuthActions = ActionsUnion<typeof authSlice.actionTypes>;
