import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { BoardPage } from '../pages/BoardPage';
import { HistoryPage } from '../pages/HistoryPage';
import { ArchivePage } from '../pages/ArchivePage';
import { CreateBoardPage } from '../pages/CreateBoardPage';
import { PolicyPage } from '../pages/PolicyPage';
import { RulesPage } from '../pages/RulesPage';
import { Footer } from '../components/Footer';
import { AuthRoute } from '../components/AuthRoute';

export const AppRouter = () => (
  <BrowserRouter>
    <Switch>
      <Route path={["/", "/create"]} component={CreateBoardPage} exact />
      <Route path="/board/:id" component={BoardPage} />
      <AuthRoute path="/history" component={HistoryPage} exact />
      <AuthRoute path="/archive" component={ArchivePage} exact />
      <AuthRoute path="/archive/:id" component={ArchivePage} />
      <Route path="/policy" component={PolicyPage} exact />
      <Route path="/rules" component={RulesPage} exact />
    </Switch>
    <Footer />
  </BrowserRouter>
);
