export const BOLDARE_PAGE_URL = 'https://boldare.com';
export const LANDING_PAGE_URL = `${BOLDARE_PAGE_URL}/sprint-retrospective-tool/`;

const windowGlobal: any = typeof window !== 'undefined' && window;
export const MEDIA_QUERY = {
  TABLET: 768,
};
export const MOBILE_VIEW = windowGlobal.innerWidth <= MEDIA_QUERY.TABLET;
export const LIMIT_VOTES_PER_USER = 50;

export enum ErrorDictionary {
  BOARD_CREATION_FAILED = 'Failed to create a board.',
  BOARD_REMOVAL_FAILED = 'Failed to remove a board.',
  BOARD_RENAMING_FAILED = 'Failed to rename a board.',
  JOINING_FAILED = 'Failed to join a board.',
  CARD_ADDING_FAILED = 'Failed to add a card.',
  CARD_REMOVAL_FAILED = 'Failed to remove a card.',
  CARD_EDITING_FAILED = 'Failed to edit a card.',
  CARD_REORDERING_FAILED = 'Failed to change the order of the cards.',
  CARD_MERGING_FAILED = 'Failed to merge the cards.',
  VOTE_ADDING_FAILED = 'You reached the votes limit',
  VOTE_REMOVAL_FAILED = 'Failed to remove the vote.',
  VOTE_VISIBILITY_CHANGING_FAILED = 'Failed to change the vote visibility.',
  COLUMN_ADDING_FAILED = 'Failed to add a column.',
  COLUMN_REMOVAL_FAILED = 'Failed to remove a column.',
  COLUMN_RENAMING_FAILED = 'Failed to rename a column.',
  COLUMN_REORDERING_FAILED = 'Failed to change column order.',
  AVAILABLE_VOTES_COUNT_CHANGE_FAILED = 'Failed to change max available votes.',
  CARD_COLUMN_CHANGE_FAILED = "Failed to change card's column.",
  NAME_CHANGE_FAILED = 'This name cannot be used',
  USERNAME_EXISTS = 'Sorry, this user name is taken',
}

export enum KeyCodes {
  ENTER_KEY_CODE = 13,
  ESCAPE_KEY_CODE = 27,
}
