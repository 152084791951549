import React from 'react';
import './Button.scss';

export interface ButtonProps {
  className?: string,
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'],
  title?: string,
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | void) => void;

  [restProps: string]: any;
};

export const Button = React.memo(({ children, className, type, title, onClick, ...rest }: ButtonProps) => (
  <button type={type} className={className} title={title} onClick={onClick} {...rest}>
    {children}
  </button>
));

Button.displayName = 'Button';
