import {
  BoardPageActionTypes,
  CLOSE_CARD_MODAL,
  CLOSE_COLUMN_MODAL,
  OPEN_CARD_MODAL,
  OPEN_COLUMN_MODAL,
  RESET_CARD_MERGE_DATA,
  SET_CARD_MERGE_DATA,
  OPEN_MERGE_MODAL,
  CLOSE_MERGE_MODAL,
} from './BoardPage.actions';

export enum CardMergeStatus {
  DataIsSet = 'DATA_IS_SET',
  DataIsNotSet = 'DATA_IS_NOT_SET',
}

export interface BoardPageState {
  columnToRemoveId: string;
  cardToRemoveId: string;
  columnToRemoveCardId: string;
  isColumnModalOpen: boolean;
  isCardModalOpen: boolean;
  isMergeModalOpen: boolean;
  mergeModalText: string;
  cardMergeStatus: CardMergeStatus;
  cardForMergeId: string;
  dragEndCardId: string;
  columnForMergeCardId: string;
  isDisabled: boolean;
}

export const boardPageState: BoardPageState = {
  columnToRemoveId: '',
  cardToRemoveId: '',
  columnToRemoveCardId: '',
  isColumnModalOpen: false,
  isCardModalOpen: false,
  isMergeModalOpen: false,
  mergeModalText: '',
  cardMergeStatus: CardMergeStatus.DataIsNotSet,
  cardForMergeId: '',
  dragEndCardId: '',
  columnForMergeCardId: '',
  isDisabled: false,
};

export function boardPageReducer(state: BoardPageState, action: BoardPageActionTypes) {
  switch (action.type) {
    case OPEN_COLUMN_MODAL:
      return {
        ...state,
        isColumnModalOpen: true,
        columnToRemoveId: action.columnToRemoveId,
      };

    case CLOSE_COLUMN_MODAL:
      return {
        ...state,
        isColumnModalOpen: false,
        columnToRemoveId: '',
      };

    case OPEN_CARD_MODAL:
      return {
        ...state,
        isCardModalOpen: true,
        cardToRemoveId: action.cardToRemoveId,
        columnToRemoveCardId: action.columnToRemoveCardId,
      };

    case CLOSE_CARD_MODAL:
      return {
        ...state,
        isCardModalOpen: false,
        cardToRemoveId: '',
        columnToRemoveCardId: '',
      };
    case OPEN_MERGE_MODAL:
      return {
        ...state,
        isMergeModalOpen: true,
        cardForMergeId: action.cardForMergeId,
        columnForMergeCardId: action.columnForMergeCardId,
        dragEndCardId: action.dragEndCardId,
        cardMergeStatus: CardMergeStatus.DataIsSet,
        mergeModalText: action.modalText,
      };
    case CLOSE_MERGE_MODAL:
      return {
        ...state,
        isMergeModalOpen: false,
        cardForMergeId: '',
        columnForMergeCardId: '',
        dragEndCardId: '',
        cardMergeStatus: CardMergeStatus.DataIsNotSet,
      };

    case SET_CARD_MERGE_DATA:
      return {
        ...state,
        cardForMergeId: action.cardForMergeId,
        columnForMergeCardId: action.columnForMergeCardId,
        cardMergeStatus: CardMergeStatus.DataIsSet,
      };

    case RESET_CARD_MERGE_DATA:
      return {
        ...state,
        cardForMergeId: '',
        columnForMergeCardId: '',
        cardMergeStatus: CardMergeStatus.DataIsNotSet,
      };

    default:
      return state;
  }
}
