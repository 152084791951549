import React, { useEffect } from 'react';
import { Modal } from '../Modal';
import { Button } from '../Button';

import './ChangeValueModal.scss';
import { translationKeys, translate } from '../../logic/translations/translations.service';

interface Props {
  isOpen: boolean;
  text: string;
  onSubmit: () => void;
  onCancel?: () => void;
  onClose: () => void;
}

export const ChangeValueModal: React.FC<Props> = ({
  isOpen,
  text,
  onSubmit,
  onClose,
  children,
}) => {
  const { saveButton } = translationKeys.pages.boardPage.modalTexts;

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      isOpen && e.key === 'Enter' && onSubmit();
    };

    window.addEventListener('keydown', listener);

    return () => window.removeEventListener('keydown', listener);
  }, [isOpen, onSubmit]);

  return (
    <Modal className="change-value-modal" isOpen={isOpen} onClose={onClose}>
      <aside>
        <p className="change-value-modal__title">{text}</p>
        {children}
        <div className="change-value-modal__btns-wrapper">
          <Button className="button button--white change-value-modal__button" onClick={onSubmit}>
            {translate(saveButton)}
          </Button>
        </div>
      </aside>
    </Modal>
  );
};
