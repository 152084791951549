import { combineEpics, Epic, ofType } from 'redux-observable';
import { tap, ignoreElements, map } from 'rxjs/operators';
import { store } from '../../../app/app.store';
import { toast } from 'react-toastify';
import { authSlice, AuthActions } from './auth.slice';
import { StoreState } from '../../../app/app.reducers';
import { translationKeys, translate } from '../../translations/translations.service';

type RootEpic = Epic<AuthActions, AuthActions, StoreState>;

const { loggedInSuccessfully, loggedOut } = translationKeys.messages;
const { errorDuringLogin, errorDuringLogout } = translationKeys.errors.login;

const googleLoginSuccessEpic$: RootEpic = (action$) =>
  action$.pipe(
    ofType(authSlice.actionTypes.googleLoginSuccess.type),
    map((action) => action.payload.response.tokenObj),
    tap(({ id_token, expires_at }) =>
      store.dispatch(
        authSlice.actions.setToken({ token: { idToken: id_token, expiresAt: expires_at } })
      )
    ),
    tap(() => toast.success(translate(loggedInSuccessfully))),
    ignoreElements()
  );

const googleLoginFailureEpic$: RootEpic = (action$) =>
  action$.pipe(
    ofType(authSlice.actionTypes.googleLoginFailure.type),
    tap(() => toast.error(translate(errorDuringLogin))),
    ignoreElements()
  );

const googleLogoutSuccessEpic$: RootEpic = (action$) =>
  action$.pipe(
    ofType(authSlice.actionTypes.googleLogoutSuccess.type),
    tap(() => store.dispatch(authSlice.actions.reset())),
    tap(() => toast.info(translate(loggedOut))),
    ignoreElements()
  );

const googleLogoutFailureEpic$: RootEpic = (action$) =>
  action$.pipe(
    ofType(authSlice.actionTypes.googleLogoutFailure.type),
    tap(() => toast.error(translate(errorDuringLogout))),
    ignoreElements()
  );

export const authEpic$ = combineEpics(
  googleLoginSuccessEpic$,
  googleLoginFailureEpic$,
  googleLogoutSuccessEpic$,
  googleLogoutFailureEpic$
);
