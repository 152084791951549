import React, { ChangeEvent, useState, SyntheticEvent } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { translate, translationKeys } from '../../logic/translations/translations.service';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as UnmergeIcon } from '../../assets/icons/detach.svg';
import { KeyCodes } from '../../common/consts';

interface Props {
  cardId: string;
  subCardId?: string;
  text?: string;
  author: string;
  onSubmit: (cardId: string, text: string, subCardId?: string) => void;
  onUnMerge?: (cardFromId: string, cardId: string) => void;
  setEditMode: (id: string, isEdited: boolean) => void;
  single?: boolean;
  isInEditMode: boolean;
}

export const BoardCardContent: React.FC<Props> = ({
  cardId,
  subCardId,
  author,
  text,
  onSubmit,
  onUnMerge,
  setEditMode,
  isInEditMode,
  single = false,
}) => {
  const [contentText, setContentText] = useState(text);

  const { split } = translationKeys.pages.boardPage.cardSettings;

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setContentText(e.target.value);
  };

  const handleOnBlur = () => {
    onSubmit(cardId, contentText ? contentText : '', subCardId);
    setEditMode(subCardId || cardId, false);
  };

  const handleUnMergeCard = (e: SyntheticEvent) => {
    e.stopPropagation();
    onUnMerge && subCardId && onUnMerge(cardId, subCardId);
  };

  const handleParagraphClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    setEditMode(subCardId || cardId, true);
  };

  const handleKeyDown = (e: any) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleOnBlur();
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent) => {
    e.keyCode === KeyCodes.ESCAPE_KEY_CODE && handleOnBlur();
  };

  return (
    <>
      <div
        className="board-card__content-text"
        onClick={(e: SyntheticEvent) => e.stopPropagation()}
      >
        {isInEditMode ? (
          <TextareaAutosize
            className={'board-card__content-textarea'}
            value={contentText}
            minRows={3}
            autoFocus
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onBlur={handleOnBlur}
          />
        ) : (
          <p style={{ whiteSpace: 'pre-line' }} onClick={handleParagraphClick}>
            {text}
          </p>
        )}
      </div>
      {!single && (
        <>
          <div className="board-card__owner">
            <UserIcon />
            <span className="board-card__owner-name">{author}</span>
          </div>

          <div className="board-card__content-divider">
            <button
              onClick={handleUnMergeCard}
              title={translate(split)}
              className="board-card__btn board-card__btn--unmerge"
            >
              <UnmergeIcon />
            </button>
          </div>
        </>
      )}
    </>
  );
};
