import { boardSlice, BoardState } from '../logic/store/board/board.slice';
import { visibilitySlice, VisibilityState } from '../logic/store/visibility/visibility.slice';
import { timerSlice, TimerState } from '../logic/store/timer/timer.slice';
import { authSlice, AuthState } from '../logic/store/auth/auth.slice';
import { trackerSlice, TrackerState } from '../logic/store/tracker/tracker.slice';

export interface StoreState {
  auth: AuthState;
  board: BoardState;
  visibility: VisibilityState;
  timer: TimerState;
  tracker: TrackerState;
}

export const reducers = {
  auth: authSlice.reducer,
  board: boardSlice.reducer,
  visibility: visibilitySlice.reducer,
  timer: timerSlice.reducer,
  tracker: trackerSlice.reducer,
};
