import { useEffect } from 'react';
import { connection } from '../../common/signalR';
import {
  BoardCreated,
  BoardJoined,
  CardAdded,
  CALLBACK,
  ColumnAdded,
  CardEdited,
  IndicateEditing,
  StoppedEditing,
  ColumnRenamed,
  CardRemoved,
  AddVote,
  RemoveVote,
  CardOrderChanged,
  ChangeCardColumn,
  ItemOrder,
  UserRejoined,
  ColumnColorChanged,
  ColumnCardsChanged,
} from './CallbackTypes';
import { tap, filter } from 'rxjs/operators';
import { useDispatch } from 'react-redux';
import { boardSlice } from '../../logic/store/board/board.slice';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import { ErrorDictionary } from '../../common/consts';
import { authSlice } from '../../logic/store/auth/auth.slice';
import { timerSlice } from '../../logic/store/timer/timer.slice';
import { Registry } from '../../models/api/api.models';

export const WebsocketHandler = () => {
  const dispatch = useDispatch();

  const initSignals = () => {
    const boardCreated$ = connection
      .signalObservable<BoardCreated>(CALLBACK.BOARD_CREATED)
      .pipe(tap((data) => dispatch(boardSlice.actions.create(data))));

    const boardJoined$ = connection.signalObservable<BoardJoined>(CALLBACK.BOARD_JOINED).pipe(
      filter((data) => !isEmpty(data.board?.id)),
      tap(({ board }) => dispatch(boardSlice.actions.setBoard({ board }))),
      tap(({ board }) =>
        dispatch(
          timerSlice.actions.setTimer({
            timerState: board?.timerState,
            timerValue: board?.timerValue,
          })
        )
      ),
      tap((data) =>
        dispatch(
          authSlice.actions.setUser({
            user: {
              id: data.userId,
              name: data?.board?.nameRegistry ? data?.board?.nameRegistry[data.userId || ''] : '',
            },
          })
        )
      )
    );

    const cardAdded$ = connection
      .signalObservable<CardAdded>(CALLBACK.CARD_ADDED)
      .pipe(tap((data) => dispatch(boardSlice.actions.addCard(data))));

    const cardEdited$ = connection
      .signalObservable<CardEdited>(CALLBACK.CARD_EDITED)
      .pipe(tap((data) => dispatch(boardSlice.actions.editCard(data))));

    const cardRemoved$ = connection
      .signalObservable<CardRemoved>(CALLBACK.CARD_REMOVED)
      .pipe(tap((data) => dispatch(boardSlice.actions.removeCard(data))));

    const cardsMerged$ = connection
      .signalObservable<ColumnCardsChanged>(CALLBACK.CARDS_MERGED)
      .pipe(tap((data) => dispatch(boardSlice.actions.updateColumnCards(data))));

    const cardUnMerged$ = connection
      .signalObservable<ColumnCardsChanged>(CALLBACK.CARD_UN_MERGED)
      .pipe(tap((data) => dispatch(boardSlice.actions.updateColumnCards(data))));

    const cardOrderChanged$ = connection
      .signalObservable<CardOrderChanged>(CALLBACK.CARD_ORDER_CHANGED)
      .pipe(tap((data) => dispatch(boardSlice.actions.setCardOrder(data))));

    const cardColumnChanged$ = connection
      .signalObservable<ChangeCardColumn>(CALLBACK.CARD_COLUMN_CHANGED)
      .pipe(tap((data) => dispatch(boardSlice.actions.changeCardColumn(data))));

    const columnRemoved$ = connection
      .signalObservable<string>(CALLBACK.COLUMN_REMOVED)
      .pipe(tap((removedColumnId) => dispatch(boardSlice.actions.removeColumn(removedColumnId))));

    const columnAdded$ = connection
      .signalObservable<ColumnAdded>(CALLBACK.COLUMN_ADDED)
      .pipe(tap((data) => dispatch(boardSlice.actions.addColumn(data))));

    const columnRenamed$ = connection.signalObservable<ColumnRenamed>(CALLBACK.COLUMN_RENAMED).pipe(
      tap((data) => {
        dispatch(boardSlice.actions.renameColumn(data));
      })
    );

    const columnOrderChanged$ = connection
      .signalObservable<ItemOrder[]>(CALLBACK.COLUMN_ORDER_CHANGED)
      .pipe(tap((data) => dispatch(boardSlice.actions.changeColumnOrder(data))));

    const indicateEditing$ = connection
      .signalObservable<IndicateEditing>(CALLBACK.INDICATE_EDITING)
      .pipe(tap((data) => dispatch(boardSlice.actions.indicateEditing(data))));

    const stoppedEditing$ = connection
      .signalObservable<StoppedEditing>(CALLBACK.STOPPED_EDITING)
      .pipe(tap((data) => dispatch(boardSlice.actions.stoppedEditing(data))));

    const error$ = connection
      .signalObservable<keyof typeof ErrorDictionary>(CALLBACK.ERROR)
      .pipe(tap((errorCode) => toast.error(ErrorDictionary[errorCode]) || 'Something went wrong'));

    const votesVisibilityChanged$ = connection
      .signalObservable<boolean>(CALLBACK.VOTES_VISIBILITY_CHANGED)
      .pipe(tap((data) => dispatch(boardSlice.actions.setVotesVisibility(data))));

    const availableVotesChanged$ = connection
      .signalObservable<number>(CALLBACK.AVAILABLE_VOTES_CHANGED)
      .pipe(
        tap((newVotesNumber) => dispatch(boardSlice.actions.setAvailableVotes(newVotesNumber)))
      );

    const authorsVisibilityChanged$ = connection
      .signalObservable<boolean>(CALLBACK.CARDS_AUTHORS_VISIBLITY_CHANGED)
      .pipe(tap((data) => dispatch(boardSlice.actions.updateAuthorsVisible(data))));

    const voteAdded$ = connection
      .signalObservable<AddVote>(CALLBACK.VOTE_ADDED)
      .pipe(tap((data) => dispatch(boardSlice.actions.addVote(data))));

    const voteRemoved$ = connection
      .signalObservable<RemoveVote>(CALLBACK.VOTE_REMOVED)
      .pipe(tap((data) => dispatch(boardSlice.actions.removeVote(data))));

    const timerStarted$ = connection
      .signalObservable<number>(CALLBACK.TIMER_STARTED)
      .pipe(tap((data) => dispatch(timerSlice.actions.start(data))));

    const timerPaused$ = connection
      .signalObservable(CALLBACK.TIMER_PAUSED)
      .pipe(tap(() => dispatch(timerSlice.actions.pause())));

    const timerResumed$ = connection
      .signalObservable(CALLBACK.TIMER_RESUMED)
      .pipe(tap(() => dispatch(timerSlice.actions.resume())));

    const timerFinished$ = connection
      .signalObservable(CALLBACK.TIMER_FINISHED)
      .pipe(tap(() => dispatch(timerSlice.actions.stop())));

    const timerStopped$ = connection
      .signalObservable(CALLBACK.TIMER_STOPPED)
      .pipe(tap(() => dispatch(timerSlice.actions.stop())));

    const timerTicked$ = connection
      .signalObservable<number>(CALLBACK.TIMER_TICKED)
      .pipe(tap((seconds) => dispatch(timerSlice.actions.tick(seconds))));

    const timerChanged$ = connection
      .signalObservable<number>(CALLBACK.TIMER_CHANGED)
      .pipe(tap((seconds) => dispatch(timerSlice.actions.tick(seconds))));

    const usernameChanged$ = connection
      .signalObservable<Registry<string>>(CALLBACK.USERNAME_CHANGED)
      .pipe(
        tap((nameRegistry) => dispatch(boardSlice.actions.updateNameRegistry(nameRegistry))),
        tap((nameRegistry) => dispatch(authSlice.actions.setUserName(nameRegistry)))
      );

    const userRejoined$ = connection.signalObservable<UserRejoined>(CALLBACK.USER_REJOINED).pipe(
      tap((userRejoinedData) => dispatch(authSlice.actions.setUserId(userRejoinedData))),
      tap((userRejoinedData) => dispatch(boardSlice.actions.changeUserIdData(userRejoinedData)))
    );

    const columnColorChanged$ = connection
      .signalObservable<ColumnColorChanged>(CALLBACK.COLUMN_COLOR_CHANGED)
      .pipe(tap((data) => dispatch(boardSlice.actions.changeColumnColor(data))));

    connection.setObservables([
      boardCreated$,
      boardJoined$,

      cardAdded$,
      cardEdited$,
      cardRemoved$,
      cardsMerged$,
      cardUnMerged$,
      cardOrderChanged$,
      cardColumnChanged$,

      columnRemoved$,
      columnAdded$,
      columnRenamed$,
      columnOrderChanged$,
      columnColorChanged$,

      indicateEditing$,
      stoppedEditing$,

      votesVisibilityChanged$,
      availableVotesChanged$,
      authorsVisibilityChanged$,
      voteAdded$,
      voteRemoved$,

      timerStarted$,
      timerPaused$,
      timerResumed$,
      timerFinished$,
      timerStopped$,
      timerTicked$,
      timerChanged$,

      usernameChanged$,
      userRejoined$,

      columnColorChanged$,

      error$,
    ]);
  };

  useEffect(() => {
    initSignals();
    connection.start();

    return connection.clearObservables;
  });

  return null;
};
