import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { store } from '../../app/app.store';
import { getToken } from '../store/auth/auth.selectors';

export const createAxios = (config?: AxiosRequestConfig | undefined): AxiosInstance =>
  axios.create(config);

export const getCommonHeaders = () => ({
  'Content-Type': 'application/json',
  'Expect-CT': 'max-age=180, enforce',
  'Feature-Policy': "microphone 'none'; camera 'none'",
  'X-Frame-Options': 'DENY',
  'X-Content-Type-Options': 'nosniff',
  'X-XSS-Protection': '1; mode=block',
});

export const getAuthenticationHeaders = (): { [key: string]: string } => {
  const token = getToken(store.getState());
  return token ? { Authorization: `Bearer ${token}` } : {};
};
