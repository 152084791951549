import { Card, Board, Column, ColumnColors } from '../../models/api/api.models';
import { Undefineable } from '../../models/Undefineable';

export interface BoardCreated {
  id?: string;
  name?: string;
  created?: string;
}

export interface BoardJoined {
  userId?: string;
  board?: Board;
  rejoined?: boolean;
}

export interface CardAdded {
  boardId?: string;
  columnId?: string;
  card?: Card;
}

export interface CardEdited {
  columnId: string;
  cardId: string;
  subcardId?: string;
  text: string;
}

export interface CardRemoved {
  columnId: string;
  cardId: string;
}

export interface ColumnCardsChanged {
  column: Column;
}

export interface CardOrderChanged {
  columnId: string;
  cardOrder: ItemOrder[];
}

export interface CardOrderChangeLocally {
  source: {
    droppableId: string;
    index: number;
  };
  destination: {
    droppableId: string;
    index: number;
  };
}

export interface ColumnAdded {
  id: string;
  name: string;
  order: number;
  color: string;
}

export interface ItemOrder {
  id: string;
  order: number;
}

export interface ChangeCardColumn {
  columnId: string;
  card: Card;
  targetColumnId: string;
  sourceCardOrder: ItemOrder[];
  cardOrder: ItemOrder[];
}

export interface IndicateEditing {
  columnId: string;
  cardId: string;
  username: string;
}

export interface StoppedEditing {
  columnId: string;
  cardId: string;
  username: string;
}

export interface ColumnRenamed {
  id: string;
  newName: string;
}

export interface AddVote {
  columnId: string;
  cardId: string;
  userId: string;
}

export interface AddVote {
  columnId: string;
  cardId: string;
  userId: string;
}

export interface TimerInit {
  timerState: Undefineable<TimerStates>;
  timerValue: Undefineable<number>;
}

export enum TimerStates {
  'STOPPED' = 0,
  'COUNTS' = 1,
  'PASUED' = 2,
}

export interface UserRejoined {
  oldUserId: string;
  newUserId: string;
  userCount: number;
}

export interface ColumnColorChanged {
  boardId: string;
  columnId: string;
  newColor: ColumnColors;
}

export interface RemoveVote extends AddVote {}

export enum CALLBACK {
  CARD_ADDED = 'cardAdded',
  CARD_EDITED = 'cardEdited',
  CARD_REMOVED = 'cardRemoved',
  CARD_ORDER_CHANGED = 'cardOrderChanged',
  CARD_COLUMN_CHANGED = 'cardColumnChanged',
  CARDS_MERGED = 'cardsMerged',
  CARD_UN_MERGED = 'cardUnMerged',
  BOARD_CREATED = 'boardCreated',
  BOARD_JOINED = 'BoardJoined',
  COLUMN_REMOVED = 'ColumnRemoved',
  COLUMN_ADDED = 'columnAdded',
  COLUMN_RENAMED = 'ColumnRenamed',
  COLUMN_ORDER_CHANGED = 'ColumnOrderChanged',
  INDICATE_EDITING = 'indicateEditing',
  STOPPED_EDITING = 'stoppedEditing',
  VOTES_VISIBILITY_CHANGED = 'voteVisibilityChanged',
  AVAILABLE_VOTES_CHANGED = 'availableVotesChanged',
  CARDS_AUTHORS_VISIBLITY_CHANGED = 'cardsAuthorsVisibilityChanged',
  VOTE_ADDED = 'voteAdded',
  VOTE_REMOVED = 'voteRemoved',
  TIMER_STARTED = 'timerStarted',
  TIMER_PAUSED = 'timerPaused',
  TIMER_RESUMED = 'timerResumed',
  TIMER_STOPPED = 'timerStopped',
  TIMER_FINISHED = 'timerFinished',
  TIMER_TICKED = 'timerTicked',
  TIMER_CHANGED = 'timerChanged',
  USERNAME_CHANGED = 'usernameChanged',
  USER_REJOINED = 'userRejoined',
  COLUMN_COLOR_CHANGED = 'columnColorChanged',
  ERROR = 'error',
}
