import React from 'react';
import { Undefineable } from '../../models/Undefineable';
import { ProgressBar } from '../ProgressBar';

export interface VotingProgress {
  current: number,
  max: number,
};

interface Props {
  values: VotingProgress
  votesPerUser: Undefineable<number>,
};

export const VotingProgressBar: React.FC<Props> = ({ values, votesPerUser }) => (
  <ProgressBar
    current={values.current}
    max={values.max}
    label={`${values.current} / ${values.max} votes | ${votesPerUser ?? '0'} per user`}
  />
);

