import { toast } from 'react-toastify';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { ignoreElements, tap } from 'rxjs/operators';
import { StoreState } from '../../../app/app.reducers';
import { store } from '../../../app/app.store';
import { translate, translationKeys } from '../../translations/translations.service';
import { BoardActions, boardSlice } from './board.slice';

type RootEpic = Epic<BoardActions, BoardActions, StoreState>;

const { columnAdded } = translationKeys.messages;

const addColumnEpic$: RootEpic = (action$) =>
  action$.pipe(
    ofType(boardSlice.actionTypes.addColumn.type),
    tap(() => toast.success(translate(columnAdded))),
    ignoreElements()
  );

const countVotes$: RootEpic = (action$, state$) =>
  action$.pipe(
    ofType(boardSlice.actionTypes.addVote.type, boardSlice.actionTypes.removeVote.type),
    tap((payload) => {
      if (payload.payload.userId === state$.value.auth.user?.id) {
        const columns = state$.value.board.board?.columns;
        let votesUsed = 0;
        if (state$.value.board.board?.votesPerUser) {
          columns?.forEach((col) =>
            col.cards?.forEach((card) => {
              const myVotes = card.votes?.filter((el) => el === state$.value.auth.user?.id);
              if (myVotes) votesUsed += myVotes.length;
            })
          );
          const votesLeft = state$.value.board.board?.votesPerUser - votesUsed;
          toast.info(`You have ${votesLeft} ${votesLeft !== 1 ? 'votes' : 'vote'} left`);
        }
      }
    }),
    ignoreElements()
  );

const addCardEpic$: RootEpic = (action$, state$) =>
  action$.pipe(
    ofType(boardSlice.actionTypes.addCard.type),
    tap(({ payload }) => {
      if (
        payload.card?.author === state$.value.auth.user?.id &&
        payload.boardId &&
        payload.columnId &&
        payload.card?.id
      ) {
        const myCard = {
          boardId: payload.boardId,
          columnId: payload.columnId,
          cardId: payload.card?.id,
        };
        store.dispatch(boardSlice.actions.myCardAdded(myCard));
      }
    }),
    ignoreElements()
  );

export const boardEpic$ = combineEpics(addColumnEpic$, addCardEpic$, countVotes$);
