import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionsUnion } from '../../redux/ActionUnion';
import { Board, BoardDescriptor,  Column, MyCard, Registry } from '../../../models/api/api.models';
import {
  CardAdded,
  ColumnAdded,
  CardEdited,
  ColumnRenamed,
  CardRemoved,
  AddVote,
  RemoveVote,
  ColumnCardsChanged,
  ChangeCardColumn,
  CardOrderChanged,
  CardOrderChangeLocally,
  ItemOrder,
  ColumnColorChanged,
  IndicateEditing,
  StoppedEditing,
  UserRejoined,
} from '../../../components/WebsocketHandler/CallbackTypes';
import { replaceRegistryId } from './board.logic';

export type BoardState = {
  board?: Board;
  descriptor?: BoardDescriptor;
  myCard?: MyCard;
};

const initialState: BoardState = {
  board: undefined,
  descriptor: undefined,
  myCard: {}
};

export const boardSlice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    reset: () => initialState,
    create: (state, action: PayloadAction<BoardDescriptor>) => {
      state.descriptor = action.payload;
    },
    myCardAdded: (state, action: PayloadAction<MyCard>) => {
      state.myCard = action.payload;
    },
    setBoard: (state, action: PayloadAction<{ board?: Board }>) => {
      state.board = action.payload.board;
    },
    addCard: (state, action: PayloadAction<CardAdded>) => {
      const card = action.payload.card;
      if (!card ) return;
      const columnToAddCard = state.board?.columns?.find(
        (column) => column.id === action.payload.columnId
      );
      if(card.order !== undefined)
      columnToAddCard?.cards?.splice(card.order, 0, card);
    },
    editCard: (state, action: PayloadAction<CardEdited>) => {
      const { columnId, cardId, text, subcardId } = action.payload;
      const columnToEditCardIdx = state?.board?.columns?.findIndex(
        (column) => column.id === columnId
      );

      if (columnToEditCardIdx !== undefined && state.board?.columns) {
        const cardToEdit = state.board.columns[columnToEditCardIdx].cards?.find(
          (card) => card.id === cardId
        );

        if (cardToEdit && cardToEdit.subCards) {
          const subCardToEdit = cardToEdit.subCards.find((subcard) => subcard.id === subcardId);
          if (subCardToEdit) {
            subCardToEdit.text = text;
            return;
          }
        }

        if (cardToEdit) {
          cardToEdit.text = text;
        }
      }
    },
    updateColumnCards: (state, action: PayloadAction<ColumnCardsChanged>) => {
      const {
        column: { id: columnId, cards: newCards },
      } = action.payload;
      const columnIdx = state.board?.columns?.findIndex((column) => column.id === columnId);

      if (columnIdx !== undefined && state.board?.columns && newCards) {
        state.board.columns[columnIdx].cards = newCards;
      }
    },
    removeCard: (state, action: PayloadAction<CardRemoved>) => {
      const { columnId, cardId } = action.payload;
      const columnToRemoveCardIdx = state?.board?.columns?.findIndex(
        (column) => column.id === columnId
      );

      if (columnToRemoveCardIdx !== undefined && state.board?.columns) {
        const cardToRemoveIdx = state.board.columns[columnToRemoveCardIdx].cards?.findIndex(
          (card) => card.id === cardId
        );

        if (cardToRemoveIdx !== undefined) {
          state?.board?.columns[columnToRemoveCardIdx].cards?.splice(cardToRemoveIdx, 1);
        }
      }
    },
    setCardOrder: (state, action: PayloadAction<CardOrderChanged>) => {
      const { columnId, cardOrder } = action.payload;
      const column = state?.board?.columns?.find((column) => column.id === columnId);

      if (column && column.cards) {
        cardOrder.forEach((cardOrder: ItemOrder) => {
          const card = column.cards?.find((card) => card.id === cardOrder.id);
          if (card) {
            card.order = cardOrder.order;
          }
        });
      }
    },
    setCardOrderLocally: (state, action: PayloadAction<CardOrderChangeLocally>) => {
      const { source, destination } = action.payload;
      const column = state?.board?.columns?.find((column) => column.id === destination.droppableId);

      if (column && column.cards) {
        const cards = Array.from(column?.cards);

        const [removedCard] = cards.splice(source.index, 1);
        removedCard.order = destination.index;
        cards.splice(destination.index, 0, removedCard);

        cards.forEach((card, index) => {
          card.order = index;
        });

        column.cards = cards;
      }
    },
    changeCardColumn: (state, action: PayloadAction<ChangeCardColumn>) => {
      const { card, cardOrder, sourceCardOrder, columnId, targetColumnId } = action.payload;

      //Get reference to source and destination columns.
      const sourceColumn = state?.board?.columns?.find((column) => column.id === columnId);
      const destinationColumn = state?.board?.columns?.find(
        (column) => column.id === targetColumnId
      );

      if (!sourceColumn || !destinationColumn) {
        return;
      }

      //Get reference and create new arrays from cards array from each column.
      const sourceCards = sourceColumn?.cards;
      const destinationCards = destinationColumn?.cards;

      if (sourceCards && destinationCards) {
        //This check is needed because card can be already in its place on users who made this change.
        //Other users needs to update their state.
        const cardIsAlreadyThere = destinationCards.findIndex((c) => c.id === card.id);
        if (cardIsAlreadyThere >= 0) {
          return;
        }

        //Go through cardOrder find card to replace and set proper order;
        cardOrder.forEach((c: any, i: number) => {
          if (c.id === card.id) {
            const removeCardIdx = sourceCards.findIndex((c) => c.id === card.id);
            const [removedCard] = sourceCards.splice(removeCardIdx, 1);
            destinationCards.splice(i, 0, removedCard);
          }
          const destinationCard = destinationCards[i];
          destinationCard.order = c.order;
        });

        //Set proper order for source cards.
        sourceCardOrder.forEach((c: ItemOrder, i: number) => {
          const sourceCard = sourceCards[i];
          if (sourceCard) {
            sourceCard.order = c.order;
          }
        });

        //Update sourceColum and destinationColumn cards with changed set of cards.
        sourceColumn.cards = sourceCards;
        destinationColumn.cards = destinationCards;
      }
    },
    changeCardColumnLocally: (state, action) => {
      const { destination, source } = action.payload;

      //Get reference to source and destination columns.
      const sourceColumn = state?.board?.columns?.find(
        (column) => column.id === source.droppableId
      );
      const destinationColumn = state?.board?.columns?.find(
        (column) => column.id === destination.droppableId
      );

      if (!sourceColumn || !destinationColumn) {
        return;
      }

      //Get reference and create new arrays from cards array from each column.
      const sourceCards = sourceColumn?.cards && Array.from(sourceColumn?.cards);
      const destinationCards = destinationColumn?.cards && Array.from(destinationColumn?.cards);

      if (sourceCards && destinationCards) {
        //Remove dragged card from source cards array, add to the destination cards array.
        const removedCard = sourceCards.find((c) => c.order === source.index);
        if (removedCard) {
          //Refresh order indexes.
          destinationCards.forEach((card) => {
            if (card.order !== undefined && card.order >= destination.index) {
              card.order++;
            }
          });
          sourceCards.forEach((card) => {
            if (card.order !== undefined && card.order >= source.index) {
              card.order--;
            }
          });

          removedCard.order = destination.index;
          destinationCards.splice(destination.index, 0, removedCard);

          //Update sourceColum and destinationColumn cards with changed set of cards.
          sourceColumn.cards = sourceCards.filter((c) => c !== removedCard);
          destinationColumn.cards = destinationCards;
        }
      }
    },
    removeColumn: (state, action: PayloadAction<string>) => {
      const columnId = action.payload;
      const columnToRemoveIdx = state?.board?.columns?.findIndex(
        (column) => column.id === columnId
      );

      if (columnToRemoveIdx !== undefined) {
        state?.board?.columns?.splice(columnToRemoveIdx, 1);
      }
    },
    addColumn: (state, action: PayloadAction<ColumnAdded>) => {
      state?.board?.columns?.push({
        ...action.payload,
        cards: [],
      });
    },
    renameColumn: (state, action: PayloadAction<ColumnRenamed>) => {
      const column = state.board?.columns?.find((column) => column.id === action.payload.id);
      if (column) {
        column.name = action.payload.newName;
      }
    },
    changeColumnColor: (state, action: PayloadAction<ColumnColorChanged>) => {
      const column = state.board?.columns?.find((column) => column.id === action.payload.columnId);
      if (column) {
        column.color = action.payload.newColor;
      }
    },
    changeColumnOrder: (state, action: PayloadAction<ItemOrder[]>) => {
      state.board?.columns?.forEach(
        (col: Column) => (col.order = action.payload.find((item) => item.id === col.id)?.order)
      );
    },
    setVotesVisibility: (state, action: PayloadAction<boolean>) => {
      state.board = { ...state.board, votesVisible: action.payload };
    },
    setAvailableVotes: (state, action: PayloadAction<number>) => {
      if (state.board) {
        state.board.votesPerUser = action.payload;
      }
    },
    addVote: (state, action: PayloadAction<AddVote>) => {
      const column = state.board?.columns?.find((column) => column.id === action.payload.columnId);
      if (column) {
        const card = column.cards?.find((card) => card.id === action.payload.cardId);
        if (card) {
          card.votes?.push(action.payload.userId);
        }
      }
    },
    removeVote: (state, action: PayloadAction<RemoveVote>) => {
      const column = state.board?.columns?.find((column) => column.id === action.payload.columnId);
      if (column) {
        const card = column.cards?.find((card) => card.id === action.payload.cardId);
        if (card) {
          const voteToRemoveIdx = card.votes?.findIndex((vote) => vote === action.payload.userId);
          if (voteToRemoveIdx !== undefined) {
            card.votes?.splice(voteToRemoveIdx, 1);
          }
        }
      }
    },
    updateNameRegistry: (state, action: PayloadAction<Registry<string>>) => {
      if (state.board) {
        state.board.nameRegistry = action.payload;
      }
    },
    updateAuthorsVisible: (state, action: PayloadAction<boolean>) => {
      if (state.board) {
        state.board.cardsAuthorsVisible = action.payload;
      }
    },
    indicateEditing: (state, action: PayloadAction<IndicateEditing>) => {
      const column = state.board?.columns?.find((column) => column.id === action.payload.columnId);
      if (column) {
        const card = column.cards?.find((card) => card.id === action.payload.cardId);
        if (card) {
          card.isBeingEdited = true;
        }
      }
    },
    stoppedEditing: (state, action: PayloadAction<StoppedEditing>) => {
      const column = state.board?.columns?.find((column) => column.id === action.payload.columnId);
      if (column) {
        const card = column.cards?.find((card) => card.id === action.payload.cardId);
        if (card) {
          card.isBeingEdited = false;
        }
      }
    },
    changeUserIdData: (state, action: PayloadAction<UserRejoined>) => {
      const { oldUserId, newUserId } = action.payload;
      state.board?.columns?.forEach((column) => {
        column.cards = column.cards?.map((card) => ({
          ...card,
          author: card.author === oldUserId ? newUserId : card.author,
          votes: card.votes?.map((id) => (id === oldUserId ? newUserId : id)),
          subCards: card.subCards?.map((subcard) => ({
            ...subcard,
            votes: subcard.votes?.map((id) => (id === oldUserId ? newUserId : id)),
          })),
        }));
      });
      if (state.board?.voteRegistry) {
        state.board.voteRegistry = replaceRegistryId<number>(
          state.board.voteRegistry,
          oldUserId,
          newUserId
        );
      }
      if (state.board?.nameRegistry) {
        state.board.nameRegistry = replaceRegistryId<string>(
          state.board.nameRegistry,
          oldUserId,
          newUserId
        );
      }
    },
  },
});

boardSlice.actionTypes = boardSlice.actions as any;

export type BoardActions = ActionsUnion<typeof boardSlice.actionTypes>;
