import React from 'react';
import { ReactComponent as ArrowLeft } from '../../assets/icons/left.svg';
import { useHistory } from "react-router-dom";
import './RulesPage.scss';

interface Props {

};

export const RulesPage: React.FC<Props> = () => {
  const browserHistory = useHistory();

  return (
    <div className="rules-page">
      <div className="rules-content">
        <div className="rules-content__button-back">
          <ArrowLeft height="25px" width="25px" onClick={() => browserHistory.goBack()} />
        </div>
        <h1 className="rules-content__header">
          BOARDS.BOLDARE.COM RULES
        </h1>
        <h2 className="rules-list__header">
          <label className="rules-list__header-label">PAR. 1</label>
          DEFINITIONS
        </h2>
        Whenever the below capitalised terms are used herein, they shall be understood as follows:
        <ol className="rules-list">
          <li>
            <label className="rules-list__bold">Rules</label> – these rules applicable to the boards.boldare.com website;
          </li>
          <li>
            <label className="rules-list__bold">BOLDARE</label> – BOLDARE S.A. with registered office in Gliwice, ul. Królowej Bony 13, 44 – 100 Gliwice, Poland, entered into the Register of Entrepreneurs of the National Court Register kept by the District
            Court for Gliwice in Gliwice, X Commercial Division of the National Court Register, under entry No.
            KRS: 0000914518, Tax Id. No. (NIP): 6312698829, the share capital of PLN 100,000.00;
          </li>
          <li>
            <label className="rules-list__bold">Website</label> – the website run by BOLDARE under the URL address boards.boldare.com.
          </li>
          <li>
            <label className="rules-list__bold">Services</label> – the services, specified in the Rules, rendered by BOLDARE for the Users via the Website;
          </li>
          <li>
            <label className="rules-list__bold">Agreement</label> – a service agreement concluded between BOLDARE and the User on terms laid down in the Rules;
          </li>
          <li>
            <label className="rules-list__bold">Programme</label> – a tool to support remote work and collaboration in distributed teams that allow the individual
            creation of virtual tables with cards, with the function of unsupported sharing of their cooperation and
            teamwork during use. An additional tool allows you to automatically define columns to which you can add
            assigned cards, voting on a card and many other editing options;
          </li>
          <li>
            <label className="rules-list__bold">User</label> – the User admitted to the Programme by the Website, which may involve any of the following: natural
            persons with full capacity to perform acts in law, legal persons or unincorporated business units with
            legal capacity assigned by particular provisions;
          </li>
          <li>
            <label className="rules-list__bold">Account</label> – the User’s individual account, secured with a login and password, allowing the User to access the Website’s content;
          </li>
          <li>
            <label className="rules-list__bold">Consumer</label> – a natural person, performing with an entrepreneur a legal act unrelated directly to such
            person’s business or professional activity, who uses the Website;
          </li>
          <li>
            <label className="rules-list__bold">Cut-Off</label> – temporary discontinuation of the operation of the Website or of its particular webpages or functions,
            required for technical reasons e.g. in connection with maintenance, retrofits or repairs of the Website;
          </li>
          <li>
            <label className="rules-list__bold">Force Majeure</label> – any external event of an extraordinary or random nature beyond control of BOLDARE and of
            the User or which cannot be prevented by them, including riots, strikes, armed conflicts, states of emergency or natural disasters;
          </li>
          <li>
            <label className="rules-list__bold">GDPR</label> – the Regulation 2016/679 of the European Parliament and of the Council of 27 April 2016 on the
            protection of natural persons with regard to the processing of personal data and on the free movement
            of such data, and repealing Directive 95/46/EC (General Data Protection Regulation)
          </li>
          <li>
            <label className="rules-list__bold">Personal Data</label> – any information relating to an identified or identifiable natural person (‘data subject’);
            an identifiable natural person is one who can be identified, directly or indirectly, in particular
            by reference to an identifier such as a name and surname, an identification number, location data,
            an online identifier or to one or more factors specific to the physical, physiological, genetic, mental,
            economic, cultural or social identity of that natural person, i.e. specific data of the Website’s
            Users required for the proper performance of the Service;
          </li>
          <li>
            <label className="rules-list__bold">Processing</label> – any operations performed on the Personal Data, such as collecting, recording, storing,
            structuring, altering, sharing and erasing, especially the ones performed in any IT systems.
          </li>
        </ol>
        <h2 className="rules-list__header">
          <label className="rules-list__header-label">PAR. 2</label>
          GENERAL PROVISIONS
        </h2>
        <ol className="rules-list">
          <li>
            The Rules specify terms of providing the Services, in particular rules for the Website’s operation,
            procedures and terms of concluding the Agreements and terms of access to the Website’s content and use thereof.
          </li>
          <li>
            BOLDARE shall render the Services and conclude the Agreements in Polish and English.
          </li>
          <li>
            To use the Website, it is necessary to accept the Rules and satisfy the requirements laid down herein.
          </li>
          <li>
            BOLDARE shall render the Services both for the Consumers and for entrepreneurs.
          </li>
        </ol>
        <h2 className="rules-list__header">
          <label className="rules-list__header-label">PAR. 3</label>
          TECHNICAL REQUIREMENTS AND RULES FOR USE OF THE WEBSITE
        </h2>
        <ol className="rules-list">
          <li>
            The User is obliged to use the Website in accordance with its purpose, in compliance with the Rules
            and in conformity with law and rules of social conduct.
          </li>
          <li>
            In order to use the Website, the User shall have the following equipment:
            <ol className="rules-sublist">
              <li>
                a computer or mobile device (e.g. smartphone, tablet) connected to the Internet and with
                software allowing for access to the Website;
              </li>
              <li>
                Mozilla Firefox browser in the latest version with enabled JavaScript and cookies or Google Chrome
                browser in the latest version with enabled JavaScript and cookies;
              </li>
              <li>
                an active Google account.
              </li>
            </ol>
          </li>
          <li>
            BOLDARE has the right to introduce the Cut-Off. BOLDARE shall inform the User of any planned Cut-Offs
            that might affect the normal use of the Website by posting information about the planned Cut-Off on the Website.
          </li>
          <li>
            In case of the Website’s failure, BOLDARE shall apply best efforts to promptly restore the Website’s functionalities.
          </li>
        </ol>
        <h2 className="rules-list__header">
          <label className="rules-list__header-label">PAR. 4</label>
          THE SERVICES
        </h2>
        <ol className="rules-list">
          <li>
            Via the Website, BOLDARE shall render the following Services for the Users:
            <ol className="rules-sublist">
              <li>
                creation of the Account,
              </li>
              <li>
                access to the Website’s content and functionalities (including participation in the Programme),
              </li>
              <li>
                technical support.
              </li>
            </ol>
          </li>
          <li>
            The Services involving access to the Website’s content and functionalities, and technical support are available exclusively for the logged Users.
          </li>
          <li>
            The scope of access to the Website’s content and functionalities and of technical support might depend on the User’s status.
          </li>
          <li>
            The Services rendered for the Users by BOLDARE shall be free of charge.
          </li>
          <li>
            The User may view the Website’s content and use its functionalities at any time, subject to Par. 3.3 hereof.
            The technical support shall be rendered in accordance with Par. 6 hereof.
          </li>
        </ol>
        <h2 className="rules-list__header">
          <label className="rules-list__header-label">PAR. 5</label>
          CREATION OF THE ACCOUNT & CONCLUSION OF THE AGREEMENT
        </h2>
        <ol className="rules-list">
          <li>
            The Website shall be available exclusively for the logged Users.
          </li>
          <li>
            Logging to the Website shall be performed via the Account.
          </li>
          <li>
            The Account shall be created by BOLDARE at the request of an interested party.
          </li>
          <li>
            For the Account to be created, the interested party needs to consent to:
            <ol className="rules-sublist">
              <li>
                the processing of personal data, having read a relevant information clause,
              </li>
              <li>
                the Rules and Privacy Policy,
              </li>
              <li>
                receiving commercial information by electronic means.
              </li>
            </ol>
          </li>
          <li>
            The service agreement between BOLDARE and the User shall be concluded upon the creation of the Account and
            it shall expire upon the submission of the statement referred to in Par. 8 hereof or in case of the deletion
            of the Account by BOLDARE, in compliance with Par. 9 hereof.
          </li>
          <li>
            If in spite of the satisfaction of the terms specified in Par. 5.4-5 BOLDARE does not confirm to have
            created the Account within 14 days, the Agreement shall not be concluded.
          </li>
          <li>
            The User must not share the Account or access data to the Account with any third parties.
          </li>
        </ol>
        <h2 className="rules-list__header">
          <label className="rules-list__header-label">PAR. 6</label>
          TECHNICAL SUPPORT
        </h2>
        <ol className="rules-list">
          <li>
            In line with rules laid down herein, BOLDARE shall render for the Users the technical support services as regards the use of the Website.
          </li>
          <li>
            The technical support as regards the use of the Website shall consist in BOLDARE’s remote support in reply
            to the Users’ notifications made via a form available on the Website or sent to an e-mail address: help.boards@boldare.com.
            The detailed scope of the activities rendered as part of the technical support services and terms of
            their performance shall be specified via the Website.
          </li>
          <li>
            The technical support services shall be provided Monday through Friday, except for Polish public holidays,
            from 9:00am to 5:00pm (CET or CEST, depending on the time currently applicable in Poland).
          </li>
        </ol>
        <h2 className="rules-list__header">
          <label className="rules-list__header-label">PAR. 7</label>
          LIABILITY
        </h2>
        <ol className="rules-list">
          <li>
            BOLDARE shall not be liable for:
            <ol className="rules-sublist">
              <li>
                the non-performance or undue performance of the Agreement if this has been caused by the Force Majeure;
              </li>
              <li>
                the non-performance or undue performance of any obligations under the Agreement to the extent caused
                by the non-performance of any obligations by the User, including but not limited to the use
                of the Website contrary to its purpose;
              </li>
              <li>
                any content posted on the Website by the Users, including for any breach of rights of third parties
                (including copyrights and author’s moral rights) in connection with such content;
              </li>
            </ol>
          </li>
          <li>
            BOLDARE’s liability for any damage arising in connection with or as a result of the non-performance or undue
            performance of the Agreement shall be limited to the amount of EUR 1,000.00 (one thousand 00/100) and shall
            not encompass any profits lost by the User. The above limitation shall apply to contractual and tort liability.
          </li>
          <li>
            The liability limitations referred to herein shall not apply to the Users who are the Consumers
            to the extent prescribed by mandatory applicable laws.
          </li>
        </ol>
        <h2 className="rules-list__header">
          <label className="rules-list__header-label">PAR. 8</label>
          RESIGNATION FROM THE SERVICES
        </h2>
        The User may resign from the Services by submitting to BOLDARE a request to delete the Account via the Website.
        <h2 className="rules-list__header">
          <label className="rules-list__header-label">PAR. 9</label>
          BLOCKING AND DELETING CONTENT AND THE ACCOUNT
        </h2>
        <ol className="rules-list">
          <li>
            BOLDARE has the right to block or delete the Account or content posted on the Website by the User who:
            <ol className="rules-sublist">
              <li>
                infringes the Rules;
              </li>
              <li>
                infringes any provisions of law;
              </li>
              <li>
                infringes image and reputation of BOLDARE or the Website;
              </li>
              <li>
                behaves in a manner that hinders other Users from using the Website.
              </li>
            </ol>
          </li>
          <li>
            If the above violations are detected, BOLDARE shall request of the User to discontinue the infringements within seven days.
          </li>
          <li>
            If the request referred to in the preceding sentence is not complied with, BOLDARE shall promptly notify
            the User by electronic means of its intention to block the access or of having deleted the Account.
          </li>
          <li>
            BOLDARE shall block the Account for 30 days. If the provisions hereof are violated once again following
            the unblocking of the Account, BOLDARE has the right to delete the Account from the Website.
          </li>
          <li>
            If BOLDARE deletes the Account in line with the rules specified herein, this shall represent the termination of the Agreement.
          </li>
        </ol>
        <h2 className="rules-list__header">
          <label className="rules-list__header-label">PAR. 10</label>
          PERSONAL DATA AND INFORMATION CLAUSE
        </h2>
        <ol className="rules-list">
          <li>
            BOLDARE shall be the controller of the Personal Data.
          </li>
          <li>
            The Service Provider’s privacy policy regulations and rules pertaining to cookies are laid down in a
            separate document, i.e. Privacy Policy Applicable at boards.boldare.com, available at the address:
          </li>
          <li>
            Information clause for the Users who set up the Account on the Website:

            “In fulfilment of the disclosure requirement pursuant to Art. 13 of the General Data Protection Regulation
            of 27 April 2016 (OJ EU L 119 of 4 May 2016 (hereinafter: the GDPR), we hereby inform the Users that:
            <ol className="rules-sublist">
              <li>
                The controller of the Users’ Personal Data shall be BOLDARE S.A. with registered office
                in Gliwice, ul. Królowej Bony 13, 44 – 100 Gliwice, Poland, entered into the Register of Entrepreneurs
                of the National Court Register kept by the District Court for Gliwice in Gliwice, X Commercial Division
                of the National Court Register, under entry No. KRS: 0000914518, Tax Id. No. (NIP): 6312698829;
              </li>
              <li>
                The controller can be contacted at the e-mail address: help.boards@boldare.com;
              </li>
              <li>
                The Personal Data provided by the Users shall be processed pursuant to Art. 6.1.a) of the GDPR in order
                to set up the Account on the Website and thereby to render the specific Services for the Users;
              </li>
              <li>
                The Personal Data provided by the Users shall be processed for the period of maintaining the Account on
                the Website or shall be stored until the submission of the request referred to in Par. 9 of the Rules
                or until the submission of a request for the erasure of the data, with the proviso that the data to be
                archived shall be kept for a period corresponding to time limits for pursuing claims related thereto;
              </li>
              <li>
                The User has the right to access to and rectification or erasure of their Personal Data or restriction
                of processing, as well as the right to data portability, the right to object against processing and
                the right to withdraw consent at any time, which shall not affect the compliance with law of the processing
                performed based on the consent prior to the withdrawal thereof;
              </li>
              <li>
                If the User detects that their Personal Data are processed in breach of the GDPR, he/she has the right
                to file a complaint with the President of the Personal Data Protection Office;
              </li>
              <li>
                The submission by the User of the specific Personal Data is voluntary; however, if they are not provided,
                this will result in inability to set up the Account on the Website and, in consequence, to provide the Services;
              </li>
              <li>
                The Personal Data provided by the Users shall be stored on servers in a third country, i.e. in the
                United States of America, based on appropriate safeguards which are standard data protection clauses
                adopted by the European Commission;
              </li>
              <li>
                The Personal Data provided by the Users shall be processed also by automated means in the form of
                profiling; the consequences of profiling entail the analysis of behaviour patterns and preferences,
                and creation of statistics.
              </li>
              <li>
                The Personal Data provided by the Users shall be visible for the controller.
              </li>
            </ol>
          </li>
        </ol>
        <h2 className="rules-list__header">
          <label className="rules-list__header-label">PAR. 11</label>
          COMPLAINTS
        </h2>
        <ol className="rules-list">
          <li>
            A complaint concerning the Service may be filed by electronic means via the Website, by e-mail to the
            address: help.boards@boldare.com or in writing to the address: BOLDARE S.A., ul. Królowej Bony 13, 44 – 100 Gliwice, Poland.
          </li>
          <li>
            The complaint should include a name and surname, e-mail address and description of a case in question.
          </li>
          <li>
            BOLDARE is obliged to reply to the complaint within 30 days as of its receipt. In case of the Consumers,
            BOLDARE shall provide the User with a reply to the complaint in writing or saved on another durable medium.
          </li>
        </ol>
        <h2 className="rules-list__header">
          <label className="rules-list__header-label">PAR. 12</label>
          OUT-OF-COURT DISPUTE RESOLUTION
        </h2>
        <ol className="rules-list">
          <li>
            Having exhausted the complaint procedure, the Consumer has the right to resort to out-of-court procedures
            for complaint resolution and pursuit of claims. The Consumer:
            <ol className="rules-sublist">
              <li>
                has the right to file a request to the Consumer Court of Arbitration by the Trade Inspection to settle
                a dispute under the Agreement;
              </li>
              <li>
                has the right to file a request to the Regional Inspector by the Trade Inspection to instigate mediation
                proceedings to amicably settle a dispute between the Consumer and BOLDARE;
              </li>
              <li>
                can receive gratuitous assistance as regards the resolution of a dispute between the Consumer and BOLDARE,
                enlisting also the assistance of a local (municipal) consumer ombudsman or a public organisation that
                has consumer protection in its statutory obligations (e.g. the Consumers’ Association, the Polish
                Consumers’ Association). The Consumers’ Association provides advice under a gratuitous consumer hotline
                number 800 007 707, while the Polish Consumers’ Association may be consulted via e-mail at the
                address: porady@dlakonsumentow.pl.
              </li>
              <li>
                The list of institutions dealing with out-of-court resolution of consumer disputes, together with a list
                of the types of relevant cases, may be consulted on the website of the Office of Competition and
                Consumer Protection at the address www.uokik.gov.pl.
              </li>
              <li>
                The Consumer may use out-of-court procedures for complaint resolution and pursuit of claims also via the
                ODR platform, in compliance with the Regulation (EU) No. 524/2013 of the European Parliament and of the
                Council of 21 May 2013 on online dispute resolution for consumer disputes and amending Regulation (EC)
                No. 2006/2004 and Directive 2009/22/EC (Regulation on Consumer ODR). When wishing to exercise an option
                of the out-of-court dispute resolution for online purchases, the Consumer may file a complaint via the
                ODR, i.e. the EU online platform available at the address: https://ec.europa.eu/consumers/odr/. The ODR
                platform shall facilitate the independent, impartial, transparent, effective, fast and fair out-of-court
                resolution of disputes between consumers and traders online concerning contractual obligations stemming
                from online sales or service contracts between a consumer resident in the Union and a trader established
                in the Union.
              </li>
              <li>
                Given that the out-of-court procedures for complaint resolution and pursuit of claims are voluntary,
                both parties must agree thereto.
              </li>
            </ol>
          </li>
        </ol>
        <h2 className="rules-list__header">
          <label className="rules-list__header-label">PAR. 13</label>
          THREATS
        </h2>
        <ol className="rules-list">
          <li>
            As any other Internet user, the User is exposed to online threats. The main threat for each Internet user
            involves potential infection of their ICT system with software created mainly with a view to causing harm
            such as viruses, bugs or Trojans. In order to avoid the relevant threats, including the ones appearing at
            the moment of opening e-mails, the Users are recommended to install on their computers used online to connect
            with the Website anti-virus software and firewall, and update them on an ongoing basis.
          </li>
          <li>
            Further, BOLDARE informs the Users that specific threats related to the use of the Website involve actions by
            hackers aiming at hacking both BOLDARE’s system (e.g. attacks on its sites) and the User’s system.
          </li>
          <li>
            BOLDARE informs the Users that it applies cutting-edge protection technologies to mitigate the risks referred
            to in Par. 15.1-2; however, it needs to be emphasised that there are no solutions available that would
            completely and fully protect against the described adverse actions.
          </li>
        </ol>
        <h2 className="rules-list__header">
          <label className="rules-list__header-label">PAR. 14</label>
          AMENDMENTS TO THE RULES
        </h2>
        <ol className="rules-list">
          <li>
            BOLDARE reserves the right to amend the Rules in justified cases, and in particular in the case of:
            <ol className="rules-sublist">
              <li>
                a change in technical conditions of the Website’s operation, including the ones related to technical
                or technological progress;
              </li>
              <li>
                a change in terms of use of software or use of devices needed to operate the Website, including the ones
                introduced by manufacturers or entities having rights to the software or to the devices in question;
              </li>
              <li>
                decisions or judgments affecting or potentially affecting the Rules or the Website’s operation
                issued by competent public bodies;
              </li>
              <li>
                the Force Majeure;
              </li>
              <li>
                formal or organisational changes at BOLDARE with a bearing on the Website’s operation.
              </li>
            </ol>
          </li>
          <li>
            BOLDARE shall inform the User of any amendments to the Rules and the scope thereof by sending an e-mail to
            the e-mail address indicated when setting up the Account. The amendment to the Rules shall enter into force
            upon the lapse of seven days following the date when the User has been informed thereof, in line with the
            preceding sentence. If the User does not agree to the amendment of the Rules, the User may resign from the
            Services in compliance with Par. 8 hereof. Any amendment to the Rules shall not affect the Services rendered
            prior to the date of its entry into force.
          </li>
        </ol>
        <h2 className="rules-list__header">
          <label className="rules-list__header-label">PAR. 15</label>
          FINAL PROVISIONS
        </h2>
        <ol className="rules-list">
          <li>
            It is forbidden to transfer any rights or obligations under these Rules to any other parties without BOLDARE’s consent.
          </li>
          <li>
            The performance of the Rules and Agreement shall be governed by the Polish law.
          </li>
          <li>
            Any disputes under the Rules or under the Agreements concluded with BOLDARE based on the Rules shall be
            settled by the Polish court competent for BOLDARE’s registered office.
          </li>
          <li>
            Par. 17.2-3 shall not apply to the Consumers insofar as this results from mandatory applicable laws.
          </li>
        </ol>
      </div>
    </div>
  );
};
