import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionsUnion } from '../../redux/ActionUnion';
import { Undefineable } from '../../../models/Undefineable';
import { TimerInit } from '../../../components/WebsocketHandler/CallbackTypes';

export interface TimerState {
  state: Undefineable<TimerStates>;
  secondsLeft: Undefineable<number>;
  visibility: boolean;
}

export enum TimerStates {
  'STOPPED' = 0,
  'COUNTS' = 1,
  'PASUED' = 2,
}

const initialState: TimerState = {
  state: undefined,
  secondsLeft: undefined,
  visibility: true,
};

export const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    setTimer: (state, action: PayloadAction<TimerInit>) => {
      const { timerValue, timerState } = action.payload; 
      state.secondsLeft = timerValue;
      state.state = timerState;
    },
    start: (state, action: PayloadAction<number>) => {
      state.secondsLeft = action.payload;
      state.state = TimerStates.COUNTS;
    },
    pause: (state) => {
      state.state = TimerStates.PASUED;
    },
    resume: (state) => {
      state.state = TimerStates.COUNTS;
    },
    stop: (state) => {
      state.state = TimerStates.STOPPED;
    },
    tick: (state, action: PayloadAction<number>) => {
      state.secondsLeft = action.payload;
    },
    setVisibility: (state, action: PayloadAction<boolean>) => {
      state.visibility = action.payload;
    },
  },
});

timerSlice.actionTypes = timerSlice.actions as any;

export type TimerActions = ActionsUnion<typeof timerSlice.actionTypes>;
