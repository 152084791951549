import React from 'react';
import clsx from 'clsx';
import { Modal } from '../Modal';

import './InformationModal.scss';

interface Props {
  isOpen: boolean;
  heading: string;
  text: string;
  onClose: () => void;
  className?: string;
}

export const InformationModal: React.FC<Props> = ({
  isOpen,
  heading,
  text,
  onClose,
  className,
}) => {
  return (
    <Modal className={clsx('info-modal', className)} isOpen={isOpen} onClose={onClose}>
      <aside>
        <p className="info-modal__heading">{heading}</p>
        <p className="info-modal__text">{text}</p>
      </aside>
    </Modal>
  );
};
